export const ciascPt = {
  pages: {
    ciasc: {
      pageTitle: 'Monitoora',
      appBar: {
        title: 'Monitoora'
      }
    }
  }
}
