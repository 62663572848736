import { yupResolver } from '@hookform/resolvers/yup'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { Loader } from '@positivote/design-system/components/Loader'
import { Typography } from '@positivote/design-system/components/Typography'
import { FormSelect } from '@positivote/design-system/components-form/Select'
import { FormTextField } from '@positivote/design-system/components-form/TextField'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { debounceEvent } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { BaseDiscipline } from '@/modules/hub/disciplines/contracts'
import {
  DisciplineDataStepForm,
  DisciplineStepperState
} from '@/modules/hub/disciplines/contracts/forms'
import { useAvailableDiscipline, useListBaseDiscipline } from '@/modules/hub/disciplines/hooks'
import { disciplineDataValidationSchema } from '@/modules/hub/disciplines/validations'

interface DisciplineDataStepProps {
  stepState: Partial<DisciplineStepperState['disciplineData']>
  setStepState: (stepperState: Partial<DisciplineStepperState['disciplineData']>) => void
  isLoading: boolean
  onChangeIsDirty: (isDirty: boolean) => void
}

export const DisciplineDataStep = forwardRef(function DisciplineDataStep(
  { setStepState, isLoading, onChangeIsDirty, stepState }: DisciplineDataStepProps,
  ref
) {
  const { profile } = useAuth()
  const [isLoadingCode, setIsLoadingCode] = useState(false)
  const [isLoadingName, setIsLoadingName] = useState(false)
  const [disciplineParams, setDisciplineParams] = useState<{ name?: string; code?: string }>({
    name: undefined,
    code: undefined
  })
  const minSearchNameLength = 3
  const baseDiscipline = useListBaseDiscipline({
    model: {
      page: 1,
      perPage: 100
    }
  })

  const parsedDisciplines = useMemo<Array<Omit<BaseDiscipline, 'code'>>>(() => {
    const disciplines = (baseDiscipline.data?.registers ?? []).map((discipline) => ({
      id: discipline.id,
      name: discipline.name
    }))
    if (
      stepState.disciplineBase &&
      !disciplines.some((discipline) => discipline.id === stepState.disciplineBase?.id)
    ) {
      disciplines.push({
        id: stepState.disciplineBase.id,
        name: stepState.disciplineBase.name
      })
    }
    return disciplines
  }, [baseDiscipline.data?.registers, stepState.disciplineBase])

  const availableDiscipline = useAvailableDiscipline({
    model: {
      name: disciplineParams.name,
      code: disciplineParams.code,
      institutionId: profile?.organizationId as unknown as string
    },
    queryOptions: {
      staleTime: 0,
      enabled:
        Boolean(
          (disciplineParams.name && disciplineParams.name.length >= minSearchNameLength) ||
            (disciplineParams.code && disciplineParams.code.length >= minSearchNameLength)
        ) && !!profile?.organizationId
    },
    onSuccess: (data) => {
      if (disciplineParams.name) {
        if (data.name === 'unavailable' && disciplineParams.name !== stepState.form?.name) {
          setIsLoadingName(false)
          setError('name', {
            message:
              i18n().modules.hub.disciplines.pages.form.stepper.disciplineData.validators
                .nameDisciplineExist
          })
        } else {
          setIsLoadingName(false)
          clearErrors('name')
        }
      }
      if (disciplineParams.code) {
        if (data.code === 'unavailable' && disciplineParams.code !== stepState.form?.code) {
          setIsLoadingCode(false)
          setError('code', {
            message:
              i18n().modules.hub.disciplines.pages.form.stepper.disciplineData.validators
                .codeDisciplineExist
          })
        } else {
          setIsLoadingCode(false)
          clearErrors('code')
        }
      }
    }
  })

  const { control, formState, setError, clearErrors, handleSubmit, reset, watch } =
    useForm<DisciplineDataStepForm>({
      mode: 'onSubmit',
      resolver: yupResolver(disciplineDataValidationSchema)
    })

  const hasError = !!Object.keys(formState.errors).length
  const isDirty = watch('name') || watch('code') || watch('disciplineBaseId')

  function handleChangeSearchName(event: React.ChangeEvent<HTMLInputElement>): void {
    const search = event.target.value || undefined
    debounceEvent(() => {
      setIsLoadingName(true)
      setDisciplineParams((oldData) => ({ ...oldData, name: search }))
    })()
  }

  function handleChangeSearchCode(event: React.ChangeEvent<HTMLInputElement>): void {
    const searchCode = event.target.value || undefined
    debounceEvent(() => {
      setIsLoadingCode(true)
      setDisciplineParams((oldData) => ({ ...oldData, code: searchCode }))
    })()
  }

  const validateDataForm = useCallback(async (): Promise<DisciplineDataStepForm | null> => {
    return new Promise((resolve) => {
      void handleSubmit(
        (data) => resolve(data),
        () => resolve(null)
      )()
    })
  }, [handleSubmit])

  useImperativeHandle(ref, () => ({ validateDataForm }), [validateDataForm])

  useEffect(() => {
    if (isDirty) {
      onChangeIsDirty(!!isDirty)
    } else {
      onChangeIsDirty(false)
    }
    // DOCS: only render when isDirty
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty])

  useEffect(() => {
    setStepState({
      ...stepState,
      hasError,
      canGoNext: !hasError && !availableDiscipline.isFetching
    })
    // DOCS: only render when hasError
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError, availableDiscipline.isFetching])

  useEffect(() => {
    if (stepState.form) {
      reset({
        code: stepState.form.code,
        disciplineBaseId: stepState.form.disciplineBaseId,
        name: stepState.form.name
      })
    }
  }, [reset, stepState.form])

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '$lg',
        minHeight: isLoading ? 180 : 'unset'
      }}
    >
      {isLoading ? (
        <Div
          css={{
            display: 'flex',
            flexDirection: 'column',
            padding: '$lg',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Loader size={80} />
        </Div>
      ) : (
        <>
          <Typography
            data-testid="Typography-requiredFields"
            variant="bodyMedium"
            css={{ color: '$on-surface-variant' }}
          >
            {i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.title}
          </Typography>

          <FormTextField
            data-testid="name"
            required
            control={control}
            name="name"
            gridProps={{ xl: 12 }}
            variant="outlined"
            label={i18n().modules.hub.disciplines.pages.form.stepper.disciplineData.discipline}
            inputProps={{
              onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeSearchName(event)
            }}
            trailingIcon={
              availableDiscipline.isLoading && isLoadingName
                ? {
                    icon: (props) => <Loader {...props} fill="$primary" />,
                    changeIconOnError: false
                  }
                : undefined
            }
            errorText={formState.errors.name?.message}
          />
          <Grid spacing="$md">
            <FormSelect
              data-testid="base-discipline"
              placement="auto"
              required
              control={control}
              name="disciplineBaseId"
              optionKeyField="id"
              optionTitleField="name"
              gridProps={{ xl: 6 }}
              options={parsedDisciplines}
              variant="outlined"
              label={
                i18n().modules.hub.disciplines.pages.form.stepper.disciplineData.baseDiscipline
              }
              onChange={(options) =>
                setStepState({
                  ...stepState,
                  disciplineBase: {
                    id: options!.id,
                    name: options!.name
                  }
                })
              }
              errorText={formState.errors.disciplineBaseId?.message}
            />
            <FormTextField
              data-testid="code"
              required
              control={control}
              name="code"
              gridProps={{ xl: 6 }}
              variant="outlined"
              label={i18n().modules.hub.disciplines.pages.form.stepper.disciplineData.code}
              inputProps={{
                onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeSearchCode(event)
              }}
              trailingIcon={
                availableDiscipline.isLoading && isLoadingCode
                  ? {
                      icon: (props) => <Loader {...props} fill="$primary" />,
                      changeIconOnError: false
                    }
                  : undefined
              }
              errorText={formState.errors.code?.message}
            />
          </Grid>
        </>
      )}
    </Div>
  )
})
