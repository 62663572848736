import { useAlert } from '@positivote/design-system/hooks'
import { UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { isDesktop, isElectron, isMobile, isTablet, isWindows } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'

import { TextDialogProps } from '@/common/components/TextDialog'
import { ApplicationException } from '@/common/exceptions'
import { useErrorHandler } from '@/common/hooks'
import { i18n } from '@/common/i18n'
import {
  HandleAccessApplicationHookParams,
  HandleAccessApplicationHookResult,
  ListApplicationHookParams,
  ListApplicationHookResult,
  ListEdtechApplicationHookParams,
  ListEdtechApplicationHookResult
} from '@/modules/hub/applications/contracts/hooks'
import { Application, AutologinMessageEvent } from '@/modules/hub/applications/contracts/models'
import {
  listApplicationService,
  listEdtechApplicationService
} from '@/modules/hub/applications/services'
import { useAuth } from '@/modules/hub/auth/contexts'
import { ptRoleToCode } from '@/modules/hub/auth/helpers'
import { useShowUserFlow } from '@/modules/hub/hubot/hooks'
import { MakeLtiLaunchHookResult } from '@/modules/hub/lti/contracts/hooks'
import { useMakeLtiLaunch } from '@/modules/hub/lti/hooks'

export const applicationHookKey = 'applications'
export const edtechApplicationHookKey = 'edtech-applications'

export function useListApplication({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListApplicationHookParams): UseQueryResult<ListApplicationHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [applicationHookKey, 'list', model],
    queryFn: async () => {
      try {
        const listApplications = await listApplicationService(model)
        onSuccess?.(listApplications)
        return listApplications
      } catch (error) {
        const parsedError = error as ApplicationException
        onError?.({ error: parsedError })
        handleError({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useListEdtechApplication({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListEdtechApplicationHookParams): UseQueryResult<
  ListEdtechApplicationHookResult,
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [edtechApplicationHookKey, 'listEdtech', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listEdtechApplications = await listEdtechApplicationService(model)
        onSuccess?.(listEdtechApplications)
        return listEdtechApplications
      } catch (error) {
        const parsedError = error as ApplicationException
        onError?.({ error: parsedError })
        handleError({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useHandleAccessApplication({
  onStartLoad,
  onEndLoad,
  onEndMakeLtiLaunch,
  openConfigureAccessDialog
}: HandleAccessApplicationHookParams): HandleAccessApplicationHookResult {
  const [dialogData, setDialogData] = useState<TextDialogProps>({
    isOpen: false,
    title: { label: '' },
    contentTexts: [],
    acceptAction: {
      handle: () => {
        setDialogData((prevState) => ({ ...prevState, isOpen: false }))
      },
      label: ''
    },
    onCancel: () => {
      setDialogData((prevState) => ({ ...prevState, isOpen: false }))
    }
  })

  const showUserFlow = useShowUserFlow()
  const { session, profile, loginProps } = useAuth()
  const makeLtiLaunch = useMakeLtiLaunch()
  const navigate = useNavigate()
  const { addAlertMessage } = useAlert()

  function openApplicationLti(ltiLaunch: MakeLtiLaunchHookResult, openNewTab = true): void {
    const hasParams = !!ltiLaunch.params && !!Object.keys(ltiLaunch.params).length
    if (!hasParams || ltiLaunch.method.toLocaleLowerCase() === 'get') {
      const queryParams = hasParams ? `?${new URLSearchParams(ltiLaunch.params).toString()}` : ''
      const url = `${ltiLaunch.action}${queryParams}`
      if (openNewTab) {
        window.open(url, '_blank')?.focus()
      } else {
        window.location.assign(url)
      }
    } else {
      const form = document.createElement('form')
      form.action = ltiLaunch.action
      form.method = ltiLaunch.method
      form.target = openNewTab ? '_blank' : '_self'
      form.innerHTML = Object.entries(ltiLaunch.params ?? {})
        .map(([name, value]) => `<input type="hidden" name="${name}" value="${value}" />`)
        .join('\n')
      document.body.appendChild(form)
      /**
       * DOCS: SetTimeout using a 0 millisecond delay in this context allows you to ensure that the form is only
       * submitted after it is added to the body of the document, in order to execute eventLoop's form submit nextTick.
       */
      setTimeout(() => form.submit(), 0)
    }
  }

  function handleAccess(application: Application): void {
    const roleCode = ptRoleToCode(session?.role ?? '')
    const currentDevice =
      isDesktop || isElectron ? 'DESKTOP' : isTablet ? 'TABLET' : isMobile ? 'SMARTPHONE' : null
    const compatibleDevices = application.compatibleDevices.length
      ? application.compatibleDevices
      : [{ name: currentDevice, compatibleSystems: [{ name: 'WEB', roles: [roleCode] }] }]
    const compatibleDeviceFound = compatibleDevices.find(
      (compatibleDevice) => compatibleDevice.name === currentDevice
    )

    if (!compatibleDeviceFound) {
      const compatibleDeviceNames = compatibleDevices.reduce<string[]>(
        (devicesArr, compatibleDevice) => {
          const parsedName =
            i18n().modules.hub.applications.device[compatibleDevice.name as 'DESKTOP']
          return !devicesArr.includes(parsedName) ? [...devicesArr, parsedName] : devicesArr
        },
        []
      )
      setDialogData((prevState) => ({
        ...prevState,
        isOpen: true,
        title: {
          label:
            i18n().modules.hub.applications.hooks.handleAccessApplication.deviceIncompatibleModal
              .title
        },
        contentTexts: [
          i18n().modules.hub.applications.hooks.handleAccessApplication.deviceIncompatibleModal.contentText(
            compatibleDeviceNames
          )
        ],
        acceptAction: {
          handle: () => setDialogData({ ...prevState, isOpen: false }),
          label:
            i18n().modules.hub.applications.hooks.handleAccessApplication.deviceIncompatibleModal
              .acceptButton
        }
      }))
      return
    }

    const appUsageFound = compatibleDeviceFound.compatibleSystems.some((compatibleSystem) =>
      compatibleSystem.roles.some((role) => roleCode === role)
    )

    if (!appUsageFound) {
      const roles: string[] = []
      compatibleDeviceFound.compatibleSystems.forEach((compatibleSystem) => {
        compatibleSystem.roles.forEach((role) => {
          const parsedRole = i18n().modules.hub.auth.role[role as 'ADMINISTRATOR']
          if (!roles.includes(parsedRole)) {
            roles.push(parsedRole)
          }
        })
      })
      setDialogData((prevState) => ({
        ...prevState,
        isOpen: true,
        title: {
          label:
            i18n().modules.hub.applications.hooks.handleAccessApplication.incompatibleModal.title
        },
        contentTexts: [
          i18n().modules.hub.applications.hooks.handleAccessApplication.incompatibleModal.contentText(
            roles
          )
        ],
        acceptAction: {
          handle: () => setDialogData({ ...prevState, isOpen: false }),
          label:
            i18n().modules.hub.applications.hooks.handleAccessApplication.incompatibleModal
              .acceptButton
        },
        refuseAction: {
          handle: () => navigate('/profiles'),
          label:
            i18n().modules.hub.applications.hooks.handleAccessApplication.incompatibleModal
              .refuseButton
        }
      }))
      return
    }

    if (application.loginType === 'AUTOLOGIN' && !isElectron) {
      setDialogData((prevState) => ({
        ...prevState,
        isOpen: true,
        title: {
          label: i18n().modules.hub.applications.hooks.handleAccessApplication.autologinModal.title
        },
        contentTexts: [
          i18n().modules.hub.applications.hooks.handleAccessApplication.autologinModal.contentText
        ],
        acceptAction: {
          handle: () =>
            window.open(
              isWindows
                ? import.meta.env.VITE_HUB_DOWNLOAD_WINDOWS_APP_URL
                : import.meta.env.VITE_HUB_DOWNLOAD_LINUX_APP_URL,
              '_self'
            ),
          label:
            i18n().modules.hub.applications.hooks.handleAccessApplication.autologinModal
              .acceptButton
        },
        refuseAction: {
          handle: () => {
            setDialogData(() => ({ ...prevState, isOpen: false }))
          },
          label:
            i18n().modules.hub.applications.hooks.handleAccessApplication.autologinModal
              .refuseButton
        }
      }))
      return
    }

    if (application.loginType === 'AUTOLOGIN' && application.code && profile?.id) {
      onStartLoad?.(application.id)
      showUserFlow.mutate({
        model: { applicationId: application.code, profileId: profile.id },
        onSuccess: (shownUserFlow) => {
          // DOCS: Always returns only a profile of the current logged user
          if (shownUserFlow.profiles[0].inputs.every((input) => !!input.data)) {
            window.postMessage({ action: 'application.autologin', appId: application.code })
          } else {
            onEndLoad?.()
            openConfigureAccessDialog?.(shownUserFlow)
          }
        },
        onError: onEndLoad
      })
      return
    }

    if (application.samlProviderToken) {
      onStartLoad?.(application.id)
      const form = document.createElement('form')
      form.action = `${import.meta.env.VITE_HUB_API_URL}/idp/signin/${
        application.samlProviderToken
      }`
      form.method = 'POST'
      form.target = '_blank'
      form.innerHTML = `<input type="hidden" name="hubIdentity" value="${session?.profileId}" />`
      document.body.appendChild(form)
      form.submit()
      onEndLoad?.()
      return
    }

    if (application.id && session?.userId) {
      onStartLoad?.(application.id)
      makeLtiLaunch.mutate({
        model: {
          applicationId: application.id,
          launchMethod: loginProps?.launchMethod,
          customUrlRedirect: loginProps?.customUrlRedirect,
          redirectUri: loginProps?.redirectUri,
          state: loginProps?.state,
          responseType: loginProps?.responseType,
          scope: loginProps?.scope
        },
        onSuccess: (data) => {
          if (!isMobile) {
            openApplicationLti(data)
          } else {
            onEndMakeLtiLaunch?.(data)
          }
          onEndLoad?.()
        },
        onError: () => {
          onEndLoad?.()
        }
      })
    }
  }

  useEffect(() => {
    function eventListener(event: MessageEvent<AutologinMessageEvent>): void {
      if (event.data.action === 'autologin.error') {
        onEndLoad?.()
        const i18nFound = i18n().modules.hub.applications.hooks.handleAccessApplication.exceptions[
          event.data.error.code as 'UNKNOWN'
        ] as ((error: AutologinMessageEvent['error']) => string) | undefined
        addAlertMessage({
          alertKey: 'autologin',
          severity: 'warning',
          hideInMs: 10000,
          subTitle:
            i18nFound?.(event.data.error) ??
            i18n().modules.hub.applications.hooks.handleAccessApplication.exceptions.UNKNOWN(
              event.data.error
            )
        })
      }
      if (event.data.action === 'autologin.success') {
        onEndLoad?.()
      }
    }

    window.addEventListener('message', eventListener)
    return () => window.removeEventListener('message', eventListener)
  }, [addAlertMessage, onEndLoad])

  return { dialogData, handleAccess, openApplicationLti }
}
