import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Div } from '@positivote/design-system/components/Div'
import { Divider } from '@positivote/design-system/components/Divider'
import { Grid } from '@positivote/design-system/components/Grid'
import { IconButton } from '@positivote/design-system/components/IconButton'
import { LI } from '@positivote/design-system/components/LI'
import { Loader } from '@positivote/design-system/components/Loader'
import { Main } from '@positivote/design-system/components/Main'
import { Pagination } from '@positivote/design-system/components/Pagination'
import { Select } from '@positivote/design-system/components/Select'
import { TextField } from '@positivote/design-system/components/TextField'
import { Typography } from '@positivote/design-system/components/Typography'
import { UL } from '@positivote/design-system/components/UL'
import { useTheme } from '@positivote/design-system/hooks'
import { ArrowRightAltIcon } from '@positivote/design-system/icons/ArrowRightAlt'
import { FilterListIcon } from '@positivote/design-system/icons/FilterList'
import { SearchIcon } from '@positivote/design-system/icons/Search'
import { Breakpoint } from '@positivote/design-system/theme'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { EmptyList } from '@/common/components/EmptyList'
import { EmptySearch } from '@/common/components/EmptySearch'
import {
  DEFAULT_BREAK_POINT_PER_PAGE,
  XL_BREAK_POINT_PER_PAGE
} from '@/common/constants/react-query'
import { debounceEvent } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { ListClassroomHookParams } from '@/modules/hub/classroom/contracts/hooks'
import { useListClassLevel, useListClassroom } from '@/modules/hub/classroom/hooks'

interface ListClassroomProps {
  navigateTo: (params?: string) => void
  appBarTitle: string
}

export function ListClassroom({ appBarTitle, navigateTo }: ListClassroomProps): JSX.Element {
  const navigate = useNavigate()
  const { breakpoint } = useTheme()

  const [showFilter, setShowFilter] = useState(false)
  const [listClassroomParams, setListClassroomParams] = useState<ListClassroomHookParams['model']>({
    page: 1,
    perPage: breakpoint === Breakpoint.xl ? XL_BREAK_POINT_PER_PAGE : DEFAULT_BREAK_POINT_PER_PAGE
  })

  const listClassLevel = useListClassLevel({ model: {} })
  const listClassroom = useListClassroom({
    model: listClassroomParams
  })

  function handleChangeSearchText(event: React.ChangeEvent<HTMLInputElement>): void {
    const search = event.target.value || undefined
    debounceEvent(() => {
      setListClassroomParams((oldState) => ({ ...oldState, search, page: 1 }))
    })()
  }

  useEffect(() => {
    if (listClassroom.data?.registers.length && !showFilter) {
      setShowFilter(true)
    }
  }, [listClassroom.data?.registers.length, showFilter])

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <AppBar title={appBarTitle} goBackFunction={() => navigate(-1)} />
      <Div
        css={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          padding: '$lg',
          overflowY: 'auto',
          gap: '$lg',
          '@sm': { padding: '$md' }
        }}
      >
        {showFilter && (
          <>
            <Div css={{ display: 'flex', flexDirection: 'column', gap: '$md' }}>
              <Div css={{ display: 'flex', alignItems: 'center', gap: '$2xs' }}>
                <FilterListIcon size={18} />
                <Typography variant="titleMedium" data-testid="Typography-titleFilter">
                  {i18n().modules.hub.classroom.components.filter.title}
                </Typography>
              </Div>
              <Grid spacing="$lg">
                <Grid xl={2} lg={3}>
                  <Select
                    label={i18n().modules.hub.classroom.components.filter.input.select}
                    hasNoneOption
                    data-testid="classLevel"
                    optionKeyField="id"
                    optionTitleField="name"
                    options={listClassLevel.data?.registers ?? []}
                    variant="outlined"
                    onChange={(level) =>
                      setListClassroomParams((oldState) => ({
                        ...oldState,
                        levelId: level?.id,
                        page: 1
                      }))
                    }
                  />
                </Grid>
                <Grid xl={10} lg={9}>
                  <TextField
                    label={i18n().modules.hub.classroom.components.filter.input.search}
                    variant="outlined"
                    data-testid="search"
                    leadingIcon={{ icon: SearchIcon }}
                    inputProps={{
                      onChange: handleChangeSearchText
                    }}
                  />
                </Grid>
              </Grid>
            </Div>
            <Divider css={{ minWidth: 1, minHeight: 1 }} />
          </>
        )}

        {!listClassroom.data?.registers.length ? (
          <Div
            css={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative'
            }}
          >
            {listClassroom.isFetching && (
              <Loader data-testid="Loader-Container-ListSchoolClass" size={80} />
            )}
            {!listClassroom.isFetching && !listClassroomParams.search && (
              <EmptyList title={i18n().modules.hub.classroom.components.emptyList} />
            )}
            {!listClassroom.isFetching && listClassroomParams.search && <EmptySearch />}
          </Div>
        ) : (
          <Div css={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Grid spacing="$md" css={{ padding: '$md $lg', marginBottom: '$2xs' }}>
              <Grid xl={3}>
                <Typography
                  variant="titleMedium"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                  data-testid="Typography-headerSelectClass"
                >
                  {i18n().modules.hub.classroom.components.header.selectClass}
                </Typography>
              </Grid>
              <Grid xl={2}>
                <Typography
                  variant="titleMedium"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                  data-testid="Typography-headerCode"
                >
                  {i18n().modules.hub.classroom.components.header.code}
                </Typography>
              </Grid>
              <Grid xl={2}>
                <Typography
                  variant="titleMedium"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                  data-testid="Typography-headerLevel"
                >
                  {i18n().modules.hub.classroom.components.header.level}
                </Typography>
              </Grid>
            </Grid>
            <UL
              css={{
                display: 'flex',
                flexDirection: 'column',
                gap: '$2xs',
                ...(listClassroom.isFetching && {
                  ...(listClassroom.data.lastPage <= 1 && { flex: 1 }),
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative'
                })
              }}
            >
              {listClassroom.isFetching && (
                <Loader data-testid="Loader-Container-ListSchoolClass" size={80} />
              )}
              {listClassroom.data.registers.map((schoolClass, index) => (
                <BaseCard
                  key={schoolClass.id}
                  data-testid={`BaseCard-Container-schoolListItem-${index}`}
                  component={LI}
                  css={{
                    opacity: listClassroom.isFetching ? '$transparent' : '$default',
                    '& .BaseCard-StateLayer': { height: 'min-content', padding: '$md $lg' }
                  }}
                >
                  <Grid spacing="$md" css={{ display: 'flex', alignItems: 'center' }}>
                    <Grid xl={3} css={{ display: 'flex', flexDirection: 'column', gap: '$2xs' }}>
                      <Typography
                        variant="bodyMedium"
                        lineClamp={1}
                        css={{ color: '$on-surface' }}
                        data-testid={`Typography-schoolClassName-${index}`}
                      >
                        {schoolClass.name}
                      </Typography>
                    </Grid>
                    <Grid xl={2} css={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography
                        variant="bodyMedium"
                        lineClamp={1}
                        css={{ color: '$on-surface' }}
                        data-testid={`Typography-schoolClassCode-${index}`}
                      >
                        {schoolClass.code}
                      </Typography>
                    </Grid>
                    <Grid xl={2} css={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography
                        variant="bodyMedium"
                        lineClamp={1}
                        css={{ color: '$on-surface' }}
                        data-testid={`Typography-schoolClassLevel-${index}`}
                      >
                        {schoolClass.levelFormatted.code}
                      </Typography>
                    </Grid>
                    <Grid
                      xl={5}
                      css={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}
                    >
                      <IconButton
                        size={32}
                        variant="standard"
                        onClick={() => navigateTo(String(schoolClass.id))}
                        data-testid={`quick-access-${index}`}
                      >
                        <ArrowRightAltIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </BaseCard>
              ))}
            </UL>
            {listClassroom.data.lastPage > 1 && (
              <Pagination
                css={{ marginTop: '$lg' }}
                lastPage={listClassroom.data.lastPage}
                page={listClassroomParams.page ?? 1}
                setPage={(page) => setListClassroomParams((oldState) => ({ ...oldState, page }))}
              />
            )}
          </Div>
        )}
      </Div>
    </Main>
  )
}
