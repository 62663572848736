import * as Dialog from '@positivote/design-system/components/Dialog'
import { Fragment } from 'react/jsx-runtime'

export interface TextDialogProps extends Omit<Dialog.DialogContainerProps, 'title'> {
  /**
   * The dialog title.
   */
  title: { label: string; icon?: React.ReactNode }
  /**
   * The content messages.
   */
  contentTexts: React.ReactNode[] | string[]
  /**
   * The accept action.
   */
  acceptAction: { handle: () => void; label: string; icon?: React.ReactNode }
  /**
   * The refuse action.
   */
  refuseAction?: { handle: () => void; label: string; icon?: React.ReactNode }
  /**
   * Content alignment.
   * @default default
   */
  align?: 'center' | 'default'
}

export function TextDialog({
  title,
  contentTexts,
  acceptAction,
  refuseAction,
  onCancel,
  align,
  isLoading,
  'data-testid': dataTestid,
  ...restProps
}: TextDialogProps): JSX.Element {
  return (
    <Dialog.Container
      data-testid={dataTestid}
      isLoading={isLoading}
      onCancel={onCancel}
      {...restProps}
    >
      <Dialog.Header data-testid={dataTestid} align={align}>
        {title.icon}
        <Dialog.HeaderTitle data-testid={dataTestid} align={align}>
          {title.label}
        </Dialog.HeaderTitle>
        <Dialog.HeaderCloseButton
          data-testid={dataTestid}
          onCancel={onCancel}
          isLoading={isLoading}
        />
      </Dialog.Header>
      <Dialog.Content data-testid={dataTestid} align={align}>
        {contentTexts.map((contentText, index) =>
          typeof contentText === 'string' ? (
            <Dialog.ContentText key={index} data-testid={`${index}-${dataTestid}`} align={align}>
              {contentText}
            </Dialog.ContentText>
          ) : (
            <Fragment key={index}>{contentText}</Fragment>
          )
        )}
      </Dialog.Content>
      <Dialog.Footer data-testid={dataTestid} align={align}>
        {refuseAction && (
          <Dialog.FooterRefuseButton
            data-testid={dataTestid}
            LeadingIcon={refuseAction.icon}
            onClick={refuseAction.handle}
            disabled={isLoading}
          >
            {refuseAction.label}
          </Dialog.FooterRefuseButton>
        )}
        <Dialog.FooterAcceptButton
          data-testid={dataTestid}
          LeadingIcon={acceptAction.icon}
          onClick={acceptAction.handle}
          isLoading={isLoading}
        >
          {acceptAction.label}
        </Dialog.FooterAcceptButton>
      </Dialog.Footer>
    </Dialog.Container>
  )
}
