import { BaseCardButton } from '@positivote/design-system/components/BaseCardButton'
import { Div } from '@positivote/design-system/components/Div'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Loader } from '@positivote/design-system/components/Loader'
import { Main } from '@positivote/design-system/components/Main'
import { Tooltip } from '@positivote/design-system/components/Tooltip'
import { Typography } from '@positivote/design-system/components/Typography'
import { useTheme } from '@positivote/design-system/hooks'
import { CollectionsBookmarkIcon } from '@positivote/design-system/icons/CollectionsBookmark'
import { GroupsIcon } from '@positivote/design-system/icons/Groups'
import { TodayIcon } from '@positivote/design-system/icons/Today'
import { Breakpoint } from '@positivote/design-system/theme'
import { useNavigate } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { LargerScreensOnly } from '@/common/components/LargerScreensOnly'
import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { OrganizationKind } from '@/modules/hub/organizations/contracts'
import { useListSchoolYear } from '@/modules/hub/school-year/hooks'

export function LevelsAndClasses(): JSX.Element {
  changePageTitle(i18n().modules.hub.dataManagement.pages.levelsAndClasses.pageTitle)
  const { breakpoint } = useTheme()
  const { profile } = useAuth()
  const navigate = useNavigate()
  const listSchoolYear = useListSchoolYear({
    model: {
      perPage: 100
    },
    queryOptions: {
      staleTime: 0
    }
  })

  const allButtonOptions = [
    {
      key: 'school-year',
      icon: <TodayIcon fill="$on-primary-container" />,
      label: i18n().modules.hub.dataManagement.pages.levelsAndClasses.option.schoolYear,
      description:
        i18n().modules.hub.dataManagement.pages.levelsAndClasses.option.schoolYearDescription,
      route: 'school-year',
      roles: ['administrador', 'instituicao'],
      permissions: [],
      organizationKind: [
        OrganizationKind.SCHOOL,
        OrganizationKind.STATE,
        OrganizationKind.TEACHING_NETWORK,
        OrganizationKind.DISTRICT,
        OrganizationKind.COMPANY,
        OrganizationKind.LOCAL,
        OrganizationKind.NATIONAL,
        OrganizationKind.GROUP,
        OrganizationKind.EDTECH,
        OrganizationKind.DEPARTMENT
      ],
      disabled: false
    },
    {
      key: 'school-classes',
      icon: <GroupsIcon fill="$on-primary-container" />,
      label: i18n().modules.hub.dataManagement.pages.levelsAndClasses.option.schoolClasses.title,
      description:
        i18n().modules.hub.dataManagement.pages.levelsAndClasses.option.schoolClasses.description,
      route: 'classroom',
      roles: ['administrador', 'instituicao'],
      permissions: [],
      organizationKind: [OrganizationKind.SCHOOL],
      disabled: !listSchoolYear.data?.registers.length
    },
    {
      key: 'discipline',
      icon: <CollectionsBookmarkIcon fill="$on-primary-container" />,
      label: i18n().modules.hub.dataManagement.pages.levelsAndClasses.option.discipline,
      description:
        i18n().modules.hub.dataManagement.pages.levelsAndClasses.option.disciplineDescription,
      route: 'discipline',
      roles: ['administrador', 'instituicao'],
      permissions: [],
      organizationKind: [OrganizationKind.SCHOOL],
      disabled: false
    }
  ]

  const filteredOptions = allButtonOptions.filter((buttonOption) => {
    return !!profile && buttonOption.organizationKind.includes(profile.organization.kindId)
  })

  function handleOpen(target: string): void {
    navigate(target)
  }

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <AppBar
        title={i18n().modules.hub.dataManagement.pages.menu.appBar.title}
        goBackFunction={() => navigate(-1)}
        breadcrumbItems={[
          {
            label:
              i18n().modules.hub.dataManagement.pages.levelsAndClasses.appBar.breadcrumbs.overview,
            onClick: () => {
              navigate(-1)
            }
          },
          {
            label: i18n().modules.hub.dataManagement.pages.levelsAndClasses.appBar.breadcrumbs.title
          }
        ]}
      />
      {breakpoint <= Breakpoint.md ? (
        <LargerScreensOnly />
      ) : (
        <Div
          css={{
            display: 'flex',
            flexDirection: 'column',
            padding: '$lg',
            flex: 1,
            gap: '$md',
            overflowY: 'auto',
            '@sm': { padding: '$md' }
          }}
        >
          {listSchoolYear.isFetching ? (
            <Div
              css={{
                display: 'flex',
                flexDirection: 'column',
                padding: '$lg',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Loader data-testid="Loader-Container-ServiceMappingList" size={80} />
            </Div>
          ) : (
            filteredOptions.map((buttonOption) =>
              buttonOption.disabled ? (
                <Tooltip
                  key={buttonOption.key}
                  data-testid="Tooltip-amountToBeInvoiced"
                  placement="auto"
                  arrow
                  label={i18n().modules.hub.dataManagement.pages.levelsAndClasses.tooltip}
                >
                  <Div>
                    <BaseCardButton
                      disabled={buttonOption.disabled}
                      key={buttonOption.key}
                      data-testid={`BaseCardButton-Container-DataManagementMenu-${buttonOption.key}`}
                      onClick={() => handleOpen(buttonOption.route)}
                      css={{
                        width: '80%',
                        '& .BaseCardButton-StateLayer': {
                          padding: '$md',
                          gap: '$md',
                          justifyContent: 'flex-start'
                        }
                      }}
                    >
                      <>
                        <IconWrapper css={{ backgroundColor: '$primary-container' }}>
                          {buttonOption.icon}
                        </IconWrapper>
                        <Div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start'
                          }}
                        >
                          <Typography
                            variant="bodyLarge"
                            css={{ textAlign: 'left', color: '$on-surface' }}
                          >
                            {buttonOption.label}
                          </Typography>
                          <Typography
                            variant="bodyMedium"
                            css={{ textAlign: 'left', color: '$on-surface-variant' }}
                          >
                            {buttonOption.description}
                          </Typography>
                        </Div>
                      </>
                    </BaseCardButton>
                  </Div>
                </Tooltip>
              ) : (
                <BaseCardButton
                  disabled={buttonOption.disabled}
                  key={buttonOption.key}
                  data-testid={`BaseCardButton-Container-DataManagementMenu-${buttonOption.key}`}
                  onClick={() => handleOpen(buttonOption.route)}
                  css={{
                    width: '80%',
                    '& .BaseCardButton-StateLayer': {
                      padding: '$md',
                      gap: '$md',
                      justifyContent: 'flex-start'
                    }
                  }}
                >
                  <>
                    <IconWrapper css={{ backgroundColor: '$primary-container' }}>
                      {buttonOption.icon}
                    </IconWrapper>
                    <Div
                      css={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                      }}
                    >
                      <Typography
                        variant="bodyLarge"
                        css={{ textAlign: 'left', color: '$on-surface' }}
                      >
                        {buttonOption.label}
                      </Typography>
                      <Typography
                        variant="bodyMedium"
                        css={{ textAlign: 'left', color: '$on-surface-variant' }}
                      >
                        {buttonOption.description}
                      </Typography>
                    </Div>
                  </>
                </BaseCardButton>
              )
            )
          )}
        </Div>
      )}
    </Main>
  )
}
