import { i18n } from '@/common/i18n'
import { listHubApiResultSanitizer } from '@/common/sanitizers'
import { hubApiService } from '@/common/services'

import {
  ActivateSchoolYearApiParams,
  ActivateSchoolYearServiceParams,
  CreateSchoolYearApiParams,
  CreateSchoolYearApiResult,
  CreateSchoolYearServiceParams,
  CreateSchoolYearServiceResult,
  ListSchoolClassApiParams,
  ListSchoolClassApiResult,
  ListSchoolClassServiceParams,
  ListSchoolClassServiceResult,
  ListSchoolYearApiParams,
  ListSchoolYearApiResult,
  ListSchoolYearServiceParams,
  ListSchoolYearServiceResult,
  PromoteSchoolYearApiParams,
  PromoteSchoolYearServiceParams,
  RemoveSchoolYearServiceParams,
  ShowSchoolYearApiResult,
  ShowSchoolYearServiceParams,
  ShowSchoolYearServiceResult,
  UpdateSchoolYearApiParams,
  UpdateSchoolYearApiResult,
  UpdateSchoolYearServiceParams,
  UpdateSchoolYearServiceResult
} from './contracts'
import {
  createSchoolYearApiParamsSanitizer,
  schoolYearFormatSanitizer,
  showSchoolYearApiResult
} from './sanitizers'

export async function listSchoolYearService({
  search,
  institutionId,
  withClassrooms,
  perPage,
  page
}: ListSchoolYearServiceParams): Promise<ListSchoolYearServiceResult> {
  const {
    data: { data, ...result }
  } = await hubApiService<ListSchoolYearApiParams, ListSchoolYearApiResult>({
    resource: i18n().modules.hub.schoolYear.resources.listSchoolYear,
    method: 'get',
    url: '/academic-sessions/v1/terms',
    params: {
      institutionId,
      search,
      withClassrooms,
      limit: perPage,
      offset: page
    }
  })

  return {
    ...listHubApiResultSanitizer({
      ...result,
      total_pages: result.pages,
      data: schoolYearFormatSanitizer(data)
    })
  }
}

export async function listSchoolClassService({
  termId,
  institutionId
}: ListSchoolClassServiceParams): Promise<ListSchoolClassServiceResult> {
  const {
    data: { data, code, title, ...result }
  } = await hubApiService<ListSchoolClassApiParams, ListSchoolClassApiResult>({
    resource: i18n().modules.hub.schoolYear.resources.listSchoolClass,
    method: 'get',
    url: '/academic-sessions/v1/terms/classes',
    params: {
      institution_id: institutionId,
      term_id: termId
    }
  })

  return {
    code,
    title,
    ...listHubApiResultSanitizer({
      ...result,
      total_pages: result.pages,
      data
    })
  }
}

export async function activateSchoolYearService({
  idSchoolYear,
  status
}: ActivateSchoolYearServiceParams): Promise<void> {
  await hubApiService<ActivateSchoolYearApiParams, null>({
    resource: i18n().modules.hub.schoolYear.resources.activateSchoolYear,
    method: 'patch',
    url: `/academic-sessions/v1/terms/${idSchoolYear}/status`,
    body: {
      status
    }
  })
}

export async function removeSchoolYearService({
  idSchoolYear
}: RemoveSchoolYearServiceParams): Promise<void> {
  await hubApiService<null, null>({
    resource: i18n().modules.hub.schoolYear.resources.removeSchoolYear,
    method: 'delete',
    url: `/academic-sessions/v1/terms/${idSchoolYear}`
  })
}

export async function createSchoolYearService({
  code,
  endDate,
  institutionId,
  startDate,
  title,
  copy
}: CreateSchoolYearServiceParams): Promise<CreateSchoolYearServiceResult> {
  const { data } = await hubApiService<CreateSchoolYearApiParams, CreateSchoolYearApiResult>({
    resource: i18n().modules.hub.schoolYear.resources.createSchoolYear,
    method: 'post',
    url: '/academic-sessions/v1/terms',
    body: {
      code,
      endDate,
      title,
      institutionId,
      startDate,
      copy
    }
  })

  return createSchoolYearApiParamsSanitizer(data)
}

export async function updateSchoolYearService({
  idSchoolYear,
  code,
  endDate,
  institutionId,
  startDate,
  title,
  copy,
  status
}: UpdateSchoolYearServiceParams): Promise<UpdateSchoolYearServiceResult> {
  const { data } = await hubApiService<UpdateSchoolYearApiParams, UpdateSchoolYearApiResult>({
    resource: i18n().modules.hub.schoolYear.resources.updateSchoolYear,
    method: 'put',
    url: `/academic-sessions/v1/terms/${idSchoolYear}`,
    body: {
      code,
      endDate,
      title,
      institutionId,
      startDate,
      copy,
      status
    }
  })

  return createSchoolYearApiParamsSanitizer(data)
}

export async function promoteSchoolYearService({
  dest,
  institutionId,
  origin,
  promotions
}: PromoteSchoolYearServiceParams): Promise<void> {
  await hubApiService<PromoteSchoolYearApiParams, null>({
    resource: i18n().modules.hub.schoolYear.resources.promoteSchoolYear,
    method: 'post',
    url: '/promotion/v1/promotion',
    body: {
      dest,
      origin,
      promotions,
      institution_id: institutionId
    }
  })
}

export async function showSchoolYearService({
  termId
}: ShowSchoolYearServiceParams): Promise<ShowSchoolYearServiceResult> {
  const { data } = await hubApiService<null, ShowSchoolYearApiResult>({
    resource: i18n().modules.hub.schoolYear.resources.promoteSchoolYear,
    method: 'get',
    url: `/academic-sessions/v1/terms/${termId}`
  })

  return showSchoolYearApiResult(data)
}
