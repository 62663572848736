import { useAlert, useTheme } from '@positivote/design-system/hooks'
import { Breakpoint } from '@positivote/design-system/theme'
import {
  UseMutationResult,
  UseQueryResult,
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'

import {
  DEFAULT_BREAK_POINT_PER_PAGE,
  XL_BREAK_POINT_PER_PAGE
} from '@/common/constants/react-query'
import { StatusCodes } from '@/common/contracts'
import { ApplicationException } from '@/common/exceptions'
import { useErrorHandler } from '@/common/hooks'
import { i18n } from '@/common/i18n'

import {
  AvailableDisciplineHookParams,
  AvailableDisciplineHookResult,
  BulkRemoveDisciplineHookParams,
  CreateDisciplineHookParams,
  CreateDisciplineHookResult,
  ListBaseDisciplineHookParams,
  ListBaseDisciplineHookResult,
  ListDisciplineHookParams,
  ListDisciplineHookResult,
  RemoveDisciplineHookParams,
  ShowDisciplineHookParams,
  ShowDisciplineHookResult,
  UpdateDisciplineHookParams,
  UpdateDisciplineHookResult
} from './contracts'
import {
  availableDisciplinesService,
  bulkRemoveDisciplineService,
  createDisciplineService,
  listBaseDisciplinesService,
  listDisciplineService,
  removeDisciplineService,
  showDisciplineService,
  updateDisciplineService
} from './services'

export const hookKey = 'discipline'

export function useListDiscipline({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListDisciplineHookParams): UseQueryResult<ListDisciplineHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'list', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listDiscipline = await listDisciplineService(model)
        onSuccess?.(listDiscipline)
        return listDiscipline
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useBulkRemoveDiscipline(): UseMutationResult<
  void,
  ApplicationException,
  BulkRemoveDisciplineHookParams
> {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()
  const { addAlertMessage } = useAlert()

  return useMutation({
    mutationKey: [hookKey, 'bulkRemove'],
    mutationFn: async ({
      model,
      onSuccess,
      onError,
      page,
      perPage,
      institutionId
    }: BulkRemoveDisciplineHookParams) => {
      try {
        await bulkRemoveDisciplineService(model)

        addAlertMessage({
          severity: 'success',
          subTitle: i18n().modules.hub.disciplines.pages.list.alert.bulkRemove
        })
        queryClient.setQueryData(
          [hookKey, 'list', { institutionId, page, perPage }],
          (oldData: ListDisciplineHookResult | undefined) =>
            oldData && {
              ...oldData,
              registers: oldData.registers.filter((register) => !model.ids.includes(register.id))
            }
        )
        void queryClient.invalidateQueries()
        onSuccess?.(null)
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useRemoveDiscipline(): UseMutationResult<
  void,
  ApplicationException,
  RemoveDisciplineHookParams
> {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()
  const { addAlertMessage } = useAlert()

  return useMutation({
    mutationKey: [hookKey, 'remove'],
    mutationFn: async ({
      model,
      onSuccess,
      onError,
      page,
      perPage,
      institutionId
    }: RemoveDisciplineHookParams) => {
      try {
        await removeDisciplineService(model)
        onSuccess?.(null)

        addAlertMessage({
          severity: 'success',
          subTitle: i18n().modules.hub.disciplines.pages.list.alert.remove
        })
        queryClient.setQueryData(
          [hookKey, 'list', { institutionId, page, perPage }],
          (oldData: ListDisciplineHookResult | undefined) =>
            oldData && {
              ...oldData,
              registers: oldData.registers.filter((register) => register.id !== model.id)
            }
        )
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useListBaseDiscipline({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListBaseDisciplineHookParams): UseQueryResult<
  ListBaseDisciplineHookResult,
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'list-base-discipline'],
    queryFn: async () => {
      try {
        const listBaseDiscipline = await listBaseDisciplinesService(model)
        onSuccess?.(listBaseDiscipline)
        return listBaseDiscipline
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useAvailableDiscipline({
  model,
  queryOptions,
  onSuccess,
  onError
}: AvailableDisciplineHookParams): UseQueryResult<
  AvailableDisciplineHookResult,
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'available-discipline', model],
    queryFn: async () => {
      try {
        const listBaseDiscipline = await availableDisciplinesService(model)
        onSuccess?.(listBaseDiscipline)
        return listBaseDiscipline
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useCreateDiscipline(): UseMutationResult<
  CreateDisciplineHookResult,
  ApplicationException,
  CreateDisciplineHookParams
> {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()
  const { addAlertMessage } = useAlert()
  const { breakpoint } = useTheme()

  return useMutation({
    mutationKey: [hookKey, 'create'],
    mutationFn: async ({ model, onSuccess, onError }: CreateDisciplineHookParams) => {
      try {
        const createDiscipline = await createDisciplineService(model)
        onSuccess?.(createDiscipline)
        addAlertMessage({
          severity: 'success',
          subTitle: i18n().modules.hub.disciplines.pages.list.alert.createDisciplina
        })
        queryClient.setQueryData(
          [hookKey, 'show', { disciplineId: String(createDiscipline.id) }],
          () => createDiscipline
        )
        queryClient.setQueryData(
          [
            hookKey,
            'list',
            {
              institutionId: model.institutionId,
              page: 1,
              perPage:
                breakpoint === Breakpoint.xl
                  ? XL_BREAK_POINT_PER_PAGE
                  : DEFAULT_BREAK_POINT_PER_PAGE
            }
          ],
          (oldData: ListDisciplineHookResult | undefined) =>
            oldData && {
              ...oldData,
              registers: [createDiscipline, ...oldData.registers].sort((a, b) =>
                a.name.localeCompare(b.name)
              )
            }
        )
        return createDiscipline
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useShowDiscipline({
  model,
  queryOptions,
  onSuccess,
  onError
}: ShowDisciplineHookParams): UseQueryResult<ShowDisciplineHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()
  const { addAlertMessage } = useAlert()

  return useQuery({
    queryKey: [hookKey, 'show', model],
    queryFn: async () => {
      try {
        const showDiscipline = await showDisciplineService(model)
        onSuccess?.(showDiscipline)
        return showDiscipline
      } catch (error) {
        const parsedError = error as ApplicationException
        if (parsedError.statusCode === StatusCodes.NOT_FOUND) {
          addAlertMessage({
            severity: 'warning',
            subTitle: i18n().common.exceptions.notFound
          })
        } else {
          handleError({ error: parsedError })
        }
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useUpdateDiscipline(): UseMutationResult<
  UpdateDisciplineHookResult,
  ApplicationException,
  UpdateDisciplineHookParams
> {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()
  const { addAlertMessage } = useAlert()
  const { breakpoint } = useTheme()

  return useMutation({
    mutationKey: [hookKey, 'update'],
    mutationFn: async ({ model, onSuccess, onError, page }: UpdateDisciplineHookParams) => {
      try {
        const updateSchoolYear = await updateDisciplineService(model)
        onSuccess?.(updateSchoolYear)
        addAlertMessage({
          severity: 'success',
          subTitle: i18n().modules.hub.disciplines.pages.list.alert.updateDisciplina
        })

        queryClient.setQueryData(
          [hookKey, 'show', { disciplineId: String(model.disciplineId) }],
          () => updateSchoolYear
        )

        queryClient.setQueryData(
          [
            hookKey,
            'list',
            {
              institutionId: model.institutionId,
              page,
              perPage:
                breakpoint === Breakpoint.xl
                  ? XL_BREAK_POINT_PER_PAGE
                  : DEFAULT_BREAK_POINT_PER_PAGE
            }
          ],
          (oldData: ListDisciplineHookResult | undefined) =>
            oldData && {
              ...oldData,
              registers: oldData.registers
                .map((discipline) => {
                  if (model.disciplineId === discipline.id) {
                    return {
                      ...discipline,
                      name: updateSchoolYear.name,
                      code: updateSchoolYear.code,
                      disciplineBaseId: updateSchoolYear.courseBaseId,
                      courseBaseName: updateSchoolYear.courseBaseName
                    }
                  } else {
                    return {
                      ...discipline
                    }
                  }
                })
                .sort((firstDiscipline, secondDiscipline) =>
                  firstDiscipline.name.localeCompare(secondDiscipline.name)
                )
            }
        )

        return updateSchoolYear
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}
