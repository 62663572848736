import * as yup from 'yup'

import { i18n } from '@/common/i18n'

export const classroomDataValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required(i18n().modules.hub.classroom.pages.form.stepper.classroomData.validators.requiredName)
    .min(3, i18n().modules.hub.classroom.pages.form.stepper.classroomData.validators.minNameLength),
  associatedSchoolYearId: yup
    .number()
    .required(
      i18n().modules.hub.classroom.pages.form.stepper.classroomData.validators
        .requiredAssociatedSchoolYear
    ),
  code: yup
    .string()
    .required(i18n().modules.hub.classroom.pages.form.stepper.classroomData.validators.requiredCode)
    .min(3, i18n().modules.hub.classroom.pages.form.stepper.classroomData.validators.minCodeLength)
})
