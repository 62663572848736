import { UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query'

import { ApplicationException } from '@/common/exceptions'
import { useErrorHandler } from '@/common/hooks'

import { ListUserHookParams, ListUserHookResult } from './contracts'
import { listUserService } from './services'

const hookKey = 'users'

export function useListUser({
  model,
  ignoreLogout,
  queryOptions,
  onSuccess,
  onError
}: ListUserHookParams): UseQueryResult<ListUserHookResult, ApplicationException> {
  const { handleError } = useErrorHandler({ ignoreLogout })

  return useQuery({
    queryKey: [hookKey, 'list', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listUsers = await listUserService(model)
        onSuccess?.(listUsers)
        return listUsers
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}
