export const schoolYearPt = {
  resources: {
    listSchoolYear: 'Listar anos letivos',
    listSchoolClass: 'Listar Classes',
    activateSchoolYear: 'Ativar ano letivo',
    removeSchoolYear: 'Remover ano letivo',
    createSchoolYear: 'Criar ano letivo',
    updateSchoolYear: 'Atualizar ano letivo',

    promoteSchoolYear: 'Promover ano letivo'
  },
  pages: {
    list: {
      pageTitle: 'Ano letivo',
      active: 'Ativo',
      inactive: 'Inativo',
      schoolYear: 'Ano letivo',
      school: 'Escola',
      start: 'Início',
      finish: 'Término',
      status: 'Status',
      allSchools: 'Todos as escola',
      classProgression: 'Progressão de turmas',
      newStatus: (status: string): string => (status === 'active' ? 'Ativo' : 'Inativo'),
      appBar: {
        title: 'Gerenciamento de dados',
        breadcrumbs: {
          overview: 'Visão geral',
          educationLevelsAndClasses: 'Níveis de ensino e turmas',
          schoolYear: 'Ano letivo'
        },
        buttonTitle: 'Incluir novo ano letivo'
      },
      filter: {
        title: 'Opções de filtragem',
        school: 'Selecionar escola',
        search: 'Buscar...'
      },
      textDialog: {
        contentText: (
          title: string
        ): string => `Todas as turmas, ensalamentos e usuários vinculados serão apagados.
          Tem certeza que deseja remover o cadastro do ${title}`
      },
      alert: {
        activate: (status: string): string => `Ano letivo ${status} com sucesso!`,
        remove: 'Ano letivo removido com sucesso!',
        create: 'Ano letivo criado com sucesso!',
        update: 'Ano letivo atualizado com sucesso!',
        promote: 'Progressão de turmas realizada com sucesso!'
      },
      emptyList: {
        title: 'Ops! Ainda não temos anos letivos para exibir',
        subTitle: 'Entre em contato com um administrador da sua instituição de ensino'
      }
    },
    form: {
      pageTitle: (isEditing: boolean): string => `${isEditing ? 'Editar' : 'Novo'} ano letivo`,
      textDialog: {
        title: 'Deseja sair do cadastro?',
        contentText:
          'Para gravar os cadastros, será necessário clicar no botão ”Finalizar cadastro”, disponível na última etapa. Caso contrário, os dados preenchidos serão perdidos.',
        refuseAction: 'Voltar para o cadastro',
        acceptAction: 'Sair do cadastro'
      },
      stepper: {
        listSchool: {
          stepTitle: 'Selecionar escola',
          pageTitle: 'Novo ano letivo',
          title:
            'Selecione as escolas no qual deseja criar o novo ano letivo. Utilize os filtros abaixo, se necessário.',
          schoolName: 'Nome da escola',
          city: 'Cidade',
          select: 'Selecionar',
          uf: 'UF',
          filter: {
            state: 'Selecionar estado',
            city: 'Selecionar cidade',
            search: 'Buscar...',
            allStates: 'Todos os estados',
            allcitys: 'Todas as cidades'
          }
        },
        schoolYearData: {
          title: '* Campo de preenchimento obrigatório',
          stepTitle: 'Dados do ano letivo',
          titleSchoolYear: 'Título do ano letivo',
          startDate: 'Data de início',
          endDate: 'Data de término',
          copyClass: 'Cópia de turmas',
          selectSchoolYear: 'Selecionar ano letivo',
          selectLinks: 'Selecionar vínculos',
          withTeacher: 'Com professores',
          noTeacher: 'Sem professores',
          newSchoolYear: 'Novo ano letivo',
          saveSchoolYear: 'Salvar cadastro',
          validators: {
            minNameLength: 'É necessário inserir um nome com no mínimo 3 caracteres',
            nameSchoolYearExist: 'Este título de ano letivo já existe',
            requiredNameSchoolYear: 'Parece que você não inseriu o título do ano letivo ainda.',
            requiredDate: (date: string): string =>
              date === 'initial'
                ? 'Você precisa selecionar uma data de início'
                : 'Você precisa selecionar uma data de término',
            requiredSchoolYear: 'Você precisa selecionar um ano letivo',
            requiredLink: 'Você precisa selecionar um tipo de vínculo',
            validateDate: (date: string): string =>
              date === 'initial'
                ? 'Você precisa indicar uma data de início válida'
                : 'Você precisa indicar uma data de término válida.',
            validateRangeDate: (date: string): string =>
              date === 'initial'
                ? 'E necessário inserir uma data anterior ao término'
                : 'E necessário inserir uma data posterior ao início'
          }
        },
        preview: {
          stepTitle: 'Pré-visualização',
          startDate: 'Data de início:',
          endDate: 'Data de término:',
          active: 'Ativo',
          inactive: 'Inativo'
        }
      }
    },
    promotion: {
      pageTitle: 'Progressão de turmas',
      appBar: {
        title: 'Gerenciamento de dados',
        buttonTitle: 'Incluir novo ano letivo'
      },
      initialDate: 'Data de início:',
      finalDate: 'Data de término:',
      origin: 'ORIGEM',
      destiny: 'DESTINO',
      save: 'Salvar',
      cancel: 'Cancelar',
      students: 'Alunos',
      selectOriginClass: 'Selecione a turma de origem',
      alert: {
        activeSchoolYear: 'Nenhum ano letivo está ativo para fazer progressão de turmas'
      }
    }
  }
}
