import * as yup from 'yup'

import { i18n } from '@/common/i18n'

export const disciplineDataValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required(
      i18n().modules.hub.disciplines.pages.form.stepper.disciplineData.validators.requiredName
    )
    .min(
      3,
      i18n().modules.hub.disciplines.pages.form.stepper.disciplineData.validators.minNameLength
    ),
  disciplineBaseId: yup
    .number()
    .required(
      i18n().modules.hub.disciplines.pages.form.stepper.disciplineData.validators
        .requiredDisciplineBase
    ),
  code: yup
    .string()
    .required(
      i18n().modules.hub.disciplines.pages.form.stepper.disciplineData.validators.requiredCode
    )
    .min(
      3,
      i18n().modules.hub.disciplines.pages.form.stepper.disciplineData.validators.minCodeLength
    )
})
