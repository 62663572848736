import { i18n } from '@/common/i18n'
import { listHubApiResultSanitizer } from '@/common/sanitizers'
import { hubApiService } from '@/common/services'

import {
  AvailableDisciplineApiParams,
  AvailableDisciplineApiResult,
  AvailableDisciplineServiceParams,
  AvailableDisciplineServiceResult,
  BulkRemoveDisciplineApiParams,
  BulkRemoveDisciplineServiceParams,
  CreateDisciplineApiParams,
  CreateDisciplineApiResult,
  CreateDisciplineServiceParams,
  CreateDisciplineServiceResult,
  ListBaseDisciplineApiParams,
  ListBaseDisciplineApiResult,
  ListBaseDisciplineServiceParams,
  ListBaseDisciplineServiceResult,
  ListDisciplineApiParams,
  ListDisciplineApiResult,
  ListDisciplineServiceParams,
  ListDisciplineServiceResult,
  RemoveDisciplineApiParams,
  RemoveDisciplineServiceParams,
  ShowDisciplineApiResult,
  ShowDisciplineServiceParams,
  ShowDisciplineServiceResult,
  UpdateDisciplineApiParams,
  UpdateDisciplineApiResult,
  UpdateDisciplineServiceParams,
  UpdateDisciplineServiceResult
} from './contracts'
import { disciplineFormatSanitizer, listDisciplineFormatSanitizer } from './sanitizers'

export async function listDisciplineService({
  search,
  institutionId,
  perPage,
  page
}: ListDisciplineServiceParams): Promise<ListDisciplineServiceResult> {
  const {
    data: { data, ...result }
  } = await hubApiService<ListDisciplineApiParams, ListDisciplineApiResult>({
    resource: i18n().modules.hub.disciplines.resources.listDiscipline,
    method: 'get',
    url: '/courses/v1/courses',
    params: {
      institution_id: institutionId,
      search,
      limit: perPage,
      offset: page
    }
  })

  return {
    ...listHubApiResultSanitizer({
      ...result,
      total_pages: result.pages,
      data: listDisciplineFormatSanitizer(data)
    })
  }
}

export async function bulkRemoveDisciplineService(
  params: BulkRemoveDisciplineServiceParams
): Promise<void> {
  await hubApiService<BulkRemoveDisciplineApiParams, null>({
    resource: i18n().modules.hub.disciplines.resources.bulkRemoveDiscipline,
    method: 'delete',
    url: '/courses/v1/courses',
    body: params
  })
}

export async function removeDisciplineService({
  id
}: RemoveDisciplineServiceParams): Promise<void> {
  await hubApiService<RemoveDisciplineApiParams, null>({
    resource: i18n().modules.hub.disciplines.resources.removeDiscipline,
    method: 'delete',
    url: `/courses/v1/courses/${id}`
  })
}

export async function listBaseDisciplinesService({
  perPage,
  page
}: ListBaseDisciplineServiceParams): Promise<ListBaseDisciplineServiceResult> {
  const {
    data: { data, ...result }
  } = await hubApiService<ListBaseDisciplineApiParams, ListBaseDisciplineApiResult>({
    resource: i18n().modules.hub.disciplines.resources.listBaseDiscipline,
    method: 'get',
    url: '/courses/v1/base-courses',
    params: {
      limit: perPage,
      offset: page
    }
  })

  return {
    ...listHubApiResultSanitizer({
      ...result,
      total_pages: result.pages,
      data
    })
  }
}

export async function availableDisciplinesService({
  code,
  name,
  institutionId
}: AvailableDisciplineServiceParams): Promise<AvailableDisciplineServiceResult> {
  const { data } = await hubApiService<AvailableDisciplineApiParams, AvailableDisciplineApiResult>({
    resource: i18n().modules.hub.disciplines.resources.availableDiscipline,
    method: 'get',
    url: '/courses/v1/courses/available',
    params: {
      code,
      name,
      institution_id: institutionId
    }
  })

  return data
}

export async function createDisciplineService({
  baseCourseId,
  code,
  institutionId,
  name
}: CreateDisciplineServiceParams): Promise<CreateDisciplineServiceResult> {
  const { data } = await hubApiService<CreateDisciplineApiParams, CreateDisciplineApiResult>({
    resource: i18n().modules.hub.disciplines.resources.createDiscipline,
    method: 'post',
    url: '/courses/v1/courses',
    body: {
      base_course_id: baseCourseId,
      code,
      institution_id: institutionId,
      name
    }
  })

  return disciplineFormatSanitizer(data)
}

export async function showDisciplineService({
  disciplineId
}: ShowDisciplineServiceParams): Promise<ShowDisciplineServiceResult> {
  const { data } = await hubApiService<null, ShowDisciplineApiResult>({
    resource: i18n().modules.hub.disciplines.resources.showDiscipline,
    method: 'get',
    url: `/courses/v1/courses/${disciplineId}`
  })

  return disciplineFormatSanitizer(data)
}

export async function updateDisciplineService({
  baseCourseId,
  code,
  institutionId,
  name,
  disciplineId
}: UpdateDisciplineServiceParams): Promise<UpdateDisciplineServiceResult> {
  const { data } = await hubApiService<UpdateDisciplineApiParams, UpdateDisciplineApiResult>({
    resource: i18n().modules.hub.disciplines.resources.updateDiscipline,
    method: 'put',
    url: `/courses/v1/courses/${disciplineId}`,
    body: {
      base_course_id: baseCourseId,
      code,
      institution_id: institutionId,
      name
    }
  })

  return disciplineFormatSanitizer(data)
}
