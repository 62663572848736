import { Div } from '@positivote/design-system/components/Div'
import { Divider } from '@positivote/design-system/components/Divider'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Typography } from '@positivote/design-system/components/Typography'
import { CollectionsBookmarkIcon } from '@positivote/design-system/icons/CollectionsBookmark'
import { forwardRef, useCallback, useImperativeHandle } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { ClassroomStepperState } from '@/modules/hub/classroom/contracts'
import { useCreateClassroom, useUpdateClassroom } from '@/modules/hub/classroom/hooks'

interface ClassroomPreviewStepProps {
  stepperState: ClassroomStepperState
}

export const ClassroomPreviewStep = forwardRef(function ClassroomPreviewStep(
  { stepperState }: ClassroomPreviewStepProps,
  ref
) {
  const { profile } = useAuth()
  const navigate = useNavigate()
  const params = useParams()
  const createClassroom = useCreateClassroom()
  const updateClassroom = useUpdateClassroom()
  const location = useLocation()
  const locationState = location.state as {
    schoolYear: { name: string; id: number }
    page: number
  } | null

  const onSubmit = useCallback(
    (setIsLoading: (isLoading: boolean) => void): void => {
      if (stepperState.classroomData.form) {
        setIsLoading(true)
        const formData = {
          baseCourseId: stepperState.classroomData.form.associatedSchoolYearId,
          code: stepperState.classroomData.form.code,
          name: stepperState.classroomData.form.name,
          institutionId: profile!.organizationId,
          schoolYearId: locationState!.schoolYear.id,
          serieId: stepperState.classroomData.form.associatedSchoolYearId
        }
        if (params.id) {
          updateClassroom.mutate({
            model: {
              classroomId: Number(params.id),
              ...formData
            },
            page: locationState?.page ?? 1,
            onSuccess: () => {
              navigate('/data-management/levels-and-classes/classroom', {
                state: {
                  schoolYear: locationState?.schoolYear
                }
              })
            }
          })
        } else {
          createClassroom.mutate({
            model: formData,
            onSuccess: () => {
              navigate('/data-management/levels-and-classes/classroom', {
                state: {
                  schoolYear: locationState?.schoolYear
                }
              })
            }
          })
        }
      }
    },
    [
      createClassroom,
      locationState,
      navigate,
      params.id,
      profile,
      stepperState.classroomData.form,
      updateClassroom
    ]
  )
  useImperativeHandle(ref, () => ({ onSubmit }), [onSubmit])

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: '$lg'
      }}
    >
      <Div
        css={{
          display: 'flex',
          borderWidth: '$thin',
          borderStyle: 'solid',
          borderRadius: '$xl',
          borderColor: '$outline-variant',
          padding: '$lg',
          gap: '$lg'
        }}
      >
        <IconWrapper size={72} css={{ backgroundColor: '$primary-container' }}>
          <CollectionsBookmarkIcon size={40} fill="$primary" />
        </IconWrapper>
        <Div css={{ display: 'flex', flexDirection: 'column', gap: '$md', flex: 1 }}>
          <Typography variant="titleLarge" css={{ color: '$on-surface' }}>
            {stepperState.classroomData.form?.name}
          </Typography>
          <Divider />
          <Div
            css={{
              display: 'flex',
              gap: '$lg'
            }}
          >
            <Div
              css={{
                display: 'flex',
                flex: 1,
                gap: '$4xs',
                flexDirection: 'column'
              }}
            >
              <Typography variant="labelLarge" css={{ color: '$on-surface' }}>
                {i18n().modules.hub.classroom.pages.form.stepper.preview.schoolYear}
              </Typography>
              <Typography variant="bodyLarge" css={{ color: '$on-surface' }}>
                {locationState?.schoolYear.name}
              </Typography>
            </Div>
            <Div
              css={{
                display: 'flex',
                flex: 1,
                gap: '$4xs',
                flexDirection: 'column'
              }}
            >
              <Typography variant="labelLarge" css={{ color: '$on-surface' }}>
                {i18n().modules.hub.classroom.pages.form.stepper.preview.associatedSchoolYear}
              </Typography>
              <Typography variant="bodyLarge" css={{ color: '$on-surface' }}>
                {stepperState.classroomData.associatedSchoolYearName}
              </Typography>
            </Div>
          </Div>
          <Div
            css={{
              display: 'flex',
              flex: 1,
              gap: '$4xs',
              flexDirection: 'column'
            }}
          >
            <Typography variant="labelLarge" css={{ color: '$on-surface' }}>
              {i18n().modules.hub.classroom.pages.form.stepper.preview.classroomCode}
            </Typography>
            <Typography variant="bodyLarge" css={{ color: '$on-surface' }}>
              {stepperState.classroomData.form?.code}
            </Typography>
          </Div>
        </Div>
      </Div>
    </Div>
  )
})
