import { Navigate, Route, Routes } from 'react-router-dom'

import { ClassroomRoutes } from '@/modules/hub/classroom/routes'
import { AccessConfiguration } from '@/modules/hub/data-management/pages/AccessConfiguration'
import { DataManagementMenu } from '@/modules/hub/data-management/pages/Menu'
import { DisciplineRoutes } from '@/modules/hub/disciplines/routes'
import { LicenseRoutes } from '@/modules/hub/licenses/routes'
import { OnboardingRoutes } from '@/modules/hub/onboarding/routes'
import { SchoolYearRoutes } from '@/modules/hub/school-year/routes'
import { SecurityConfigurationRoutes } from '@/modules/hub/security-configuration/routes'
import { UsersRoutes } from '@/modules/hub/users/routes'

import { LevelsAndClasses } from './pages/LevelsAndClasses'

export function DataManagementRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<DataManagementMenu />} />
      <Route path="/access-configuration" element={<AccessConfiguration />} />
      <Route path="/access-configuration/*" element={<LicenseRoutes />} />
      <Route path="/onboarding/*" element={<OnboardingRoutes />} />
      <Route path="/security-configuration/*" element={<SecurityConfigurationRoutes />} />
      <Route path="/my-users/*" element={<UsersRoutes />} />
      <Route path="/levels-and-classes/*" element={<LevelsAndClasses />} />
      <Route path="/levels-and-classes/school-year/*" element={<SchoolYearRoutes />} />
      <Route path="/levels-and-classes/discipline/*" element={<DisciplineRoutes />} />
      <Route path="/levels-and-classes/classroom/*" element={<ClassroomRoutes />} />

      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </Routes>
  )
}
