import { Discipline, DisciplineApi, ListDisciplineApiResult } from './contracts'

export function listDisciplineFormatSanitizer(
  disciplines: ListDisciplineApiResult['data']
): Discipline[] {
  return disciplines.map((discipline) => ({
    id: discipline.id,
    code: discipline.code,
    courseBaseId: discipline.course_base_id,
    courseBaseName: discipline.course_base_name,
    name: discipline.name,
    uuid: discipline.uuid
  }))
}

export function disciplineFormatSanitizer(discipline: DisciplineApi): Discipline {
  return {
    id: discipline.id,
    code: discipline.code,
    courseBaseId: discipline.course_base_id,
    courseBaseName: discipline.course_base_name,
    name: discipline.name,
    uuid: discipline.uuid
  }
}
