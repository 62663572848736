import { i18n } from '@/common/i18n'
import { listHubApiResultSanitizer } from '@/common/sanitizers'
import { hubApiService } from '@/common/services'

import {
  ListUserApiParams,
  ListUserApiResult,
  ListUserServiceParams,
  ListUserServiceResult
} from './contracts'
import { fromUserApiSanitizer } from './sanitizers'

export async function listUserService(
  params: ListUserServiceParams
): Promise<ListUserServiceResult> {
  const {
    data: { data, account, ...result }
  } = await hubApiService<ListUserApiParams, ListUserApiResult>({
    resource: i18n().modules.hub.profiles.resources.list,
    method: 'get',
    url: '/users/v1/users',
    params: {
      org_id: params.orgId,
      app_id: params.appId,
      search: params.search,
      role_code: params.roleCode,
      page: params.page,
      size: params.perPage
    }
  })

  return {
    ...listHubApiResultSanitizer({ ...result, data: fromUserApiSanitizer(data) }),
    account
  }
}
