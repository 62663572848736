import { Div } from '@positivote/design-system/components/Div'
import { Divider } from '@positivote/design-system/components/Divider'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Typography } from '@positivote/design-system/components/Typography'
import { CollectionsBookmarkIcon } from '@positivote/design-system/icons/CollectionsBookmark'
import { forwardRef, useCallback, useImperativeHandle } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { DisciplineStepperState } from '@/modules/hub/disciplines/contracts/forms'
import { useCreateDiscipline, useUpdateDiscipline } from '@/modules/hub/disciplines/hooks'

interface DisciplinePreviewStepProps {
  stepperState: DisciplineStepperState
}

export const DisciplinePreviewStep = forwardRef(function DisciplinePreviewStep(
  { stepperState }: DisciplinePreviewStepProps,
  ref
) {
  const { profile } = useAuth()
  const navigate = useNavigate()
  const params = useParams()
  const createDiscipline = useCreateDiscipline()
  const updateSchoolYear = useUpdateDiscipline()
  const location = useLocation()
  const locationState = location.state as { page: number } | null

  const onSubmit = useCallback(
    (setIsLoading: (isLoading: boolean) => void): void => {
      if (stepperState.disciplineData.form) {
        setIsLoading(true)
        const formData = {
          baseCourseId: stepperState.disciplineData.form.disciplineBaseId,
          code: stepperState.disciplineData.form.code,
          institutionId: profile!.organizationId,
          name: stepperState.disciplineData.form.name
        }
        if (params.id) {
          updateSchoolYear.mutate({
            model: {
              disciplineId: Number(params.id),
              ...formData
            },
            page: locationState?.page ?? 1,
            onSuccess: () => {
              navigate(-1)
            }
          })
        } else {
          createDiscipline.mutate({
            model: {
              baseCourseId: stepperState.disciplineData.form.disciplineBaseId,
              code: stepperState.disciplineData.form.code,
              institutionId: profile!.organizationId,
              name: stepperState.disciplineData.form.name
            },
            onSuccess: () => {
              navigate(-1)
            }
          })
        }
      }
    },
    [
      createDiscipline,
      locationState?.page,
      navigate,
      params.id,
      profile,
      stepperState.disciplineData.form,
      updateSchoolYear
    ]
  )
  useImperativeHandle(ref, () => ({ onSubmit }), [onSubmit])

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: '$lg'
      }}
    >
      <Div
        css={{
          display: 'flex',
          borderWidth: '$thin',
          borderStyle: 'solid',
          borderRadius: '$xl',
          borderColor: '$outline-variant',
          padding: '$lg',
          gap: '$lg'
        }}
      >
        <IconWrapper size={72} css={{ backgroundColor: '$primary-container' }}>
          <CollectionsBookmarkIcon size={40} fill="$primary" />
        </IconWrapper>
        <Div css={{ display: 'flex', flexDirection: 'column', gap: '$md', flex: 1 }}>
          <Typography
            data-testid="Typography-name"
            variant="titleLarge"
            css={{ color: '$on-surface' }}
          >
            {stepperState.disciplineData.form?.name}
          </Typography>
          <Divider />
          <Div
            css={{
              display: 'flex',
              gap: '$lg'
            }}
          >
            <Div
              css={{
                display: 'flex',
                flex: 1,
                gap: '$4xs',
                flexDirection: 'column'
              }}
            >
              <Typography
                data-testid="Typography-code-title"
                variant="labelLarge"
                css={{ color: '$on-surface' }}
              >
                {i18n().modules.hub.disciplines.pages.form.stepper.preview.code}
              </Typography>
              <Typography
                data-testid="Typography-code-value"
                variant="bodyLarge"
                css={{ color: '$on-surface' }}
              >
                {stepperState.disciplineData.form?.code}
              </Typography>
            </Div>
            <Div
              css={{
                display: 'flex',
                flex: 1,
                gap: '$4xs',
                flexDirection: 'column'
              }}
            >
              <Typography
                data-testid="Typography-baseDiscipline-title"
                variant="labelLarge"
                css={{ color: '$on-surface' }}
              >
                {i18n().modules.hub.disciplines.pages.form.stepper.preview.baseDiscipline}
              </Typography>
              <Typography
                data-testid="Typography-baseDiscipline-value"
                variant="bodyLarge"
                css={{ color: '$on-surface' }}
              >
                {stepperState.disciplineData.disciplineBase?.name}
              </Typography>
            </Div>
          </Div>
        </Div>
      </Div>
    </Div>
  )
})
