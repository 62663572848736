import { useAlert, useTheme } from '@positivote/design-system/hooks'
import { useQueryClient } from '@tanstack/react-query'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { MAX_PER_PAGE } from '@/common/constants/react-query'
import {
  ApplicationException,
  NotFoundException,
  UnprocessableEntityException
} from '@/common/exceptions'
import { debounceEvent, getAliasFromUrl, getHashParams, safetyJSONParse } from '@/common/helpers'
import { useErrorHandler } from '@/common/hooks'
import { i18n } from '@/common/i18n'
import { checkIsIdle, setHubApiClientSession } from '@/common/services'
import { Account } from '@/modules/hub/accounts/contracts/models'
import { useHandleAccessApplication } from '@/modules/hub/applications/hooks'
import { LtiApplication } from '@/modules/hub/lti/contracts/models'
import { useMakeLtiLaunch } from '@/modules/hub/lti/hooks'
import {
  getLtiApplicationSessionRepository,
  removeLtiApplicationSessionRepository,
  saveLtiApplicationSessionRepository
} from '@/modules/hub/lti/repositories'
import { showLtiApplicationService } from '@/modules/hub/lti/services'
import { ProfileDetailsFormatted } from '@/modules/hub/profiles/contracts'
import { useListProfileAndSetCache } from '@/modules/hub/profiles/hooks'
import { theme } from '@/modules/hub/white-label/constants'
import { WhiteLabel } from '@/modules/hub/white-label/contracts'
import {
  getWhiteLabelSessionRepository,
  removeWhiteLabelSessionRepository,
  saveWhiteLabelSessionRepository
} from '@/modules/hub/white-label/repositories'
import { showWhiteLabelByAliasService } from '@/modules/hub/white-label/services'
import { MONITOORA_ROLES } from '@/modules/monitoora/constants'

import {
  ChangeProfileHookParams,
  CreateGoogleSessionHookParams,
  CreateMicrosoftSessionHookParams,
  CreateSessionHookParams,
  ShowSessionHookParams
} from './contracts/hooks'
import {
  AuthUrlParams,
  LoginProps,
  OAuth2ResponseType,
  Permission,
  Session,
  Token
} from './contracts/models'
import { AuthFlow } from './contracts/services'
import { decodeTokenPayload } from './helpers'
import {
  getLoginPropsSessionRepository,
  getSessionRepository,
  getTokenRepository,
  removeLoginPropsSessionRepository,
  saveLoginPropsSessionRepository,
  saveSessionSupportRepository,
  saveTokenSupportRepository
} from './repositories'
import {
  createSessionService,
  loadHubApiClientService,
  logoutSessionService,
  logoutSessionSupportService,
  showSessionService
} from './services'

interface AuthStateProps {
  isLoading: boolean
  isLoadingMicrosoft: boolean
  isLoadingGoogle: boolean
  isLoadingWhiteLabel: boolean
  isLoadingSsoApplication: boolean
  isSigned: boolean
  isLoginSupport?: boolean
  session?: Session
  profile?: ProfileDetailsFormatted
  account?: Account
  permissions?: Permission[]
  whiteLabel?: WhiteLabel
  ssoApplication?: LtiApplication
  loginProps?: LoginProps
}

type AuthContextProps = AuthStateProps & {
  saveProfile: (updatedProfile: ProfileDetailsFormatted) => void
  saveWhiteLabel: (whiteLabel: WhiteLabel) => void
  saveAccount: (updateAccount: Account) => void
  getToken: () => Token | null
  prepareOAuth2AndSso: () => void
  logout: () => void
  showSession: (params: ShowSessionHookParams) => Promise<void>
  logoutSupport: () => void
  changeProfile: (params: ChangeProfileHookParams) => Promise<void>
  createSession: <CurrentAuthFlow extends AuthFlow>(
    params: CreateSessionHookParams<CurrentAuthFlow>
  ) => Promise<void>
  createSessionMicrosoft: (params: CreateMicrosoftSessionHookParams) => void
  createSessionGoogle: (params: CreateGoogleSessionHookParams) => void
}

const INITIAL_STATE: AuthStateProps = {
  isLoading: false,
  isLoadingMicrosoft: false,
  isLoadingGoogle: false,
  isLoadingWhiteLabel: false,
  isLoadingSsoApplication: false,
  isSigned: false,
  isLoginSupport: false,
  session: undefined,
  profile: undefined,
  account: undefined,
  permissions: undefined,
  whiteLabel: undefined,
  ssoApplication: undefined,
  loginProps: undefined
}
const AuthContext = createContext<AuthContextProps>(INITIAL_STATE as AuthContextProps)

let isCreatingSessionMicrosoft = false
let isCreatingSessionGoogle = false

// TODO: Refactor this AuthProvider, because is to large
export function AuthProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const { handleError } = useErrorHandler({ ignoreLogout: true })
  const { setTheme } = useTheme()
  const { addAlertMessage } = useAlert()

  const listProfileAndSetCache = useListProfileAndSetCache()
  const makeLtiLaunch = useMakeLtiLaunch()

  const { openApplicationLti } = useHandleAccessApplication({})

  const location = useLocation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [searchParams] = useSearchParams()
  const [, pathname] = location.pathname.split('/')
  const isOAuth2Path = pathname === 'oauth2'
  const isSsoPath = pathname === 'sso'
  const isOAuth2OrSsoPath = isOAuth2Path || isSsoPath
  const isRootPath = pathname === ''
  const isLoginPath = isOAuth2OrSsoPath || isRootPath || !!getAliasFromUrl()
  const hashParams = getHashParams()
  const hashParamsStateParsed = safetyJSONParse<AuthUrlParams>(hashParams.state)
  const locationState = location.state as { keepLoginProps?: boolean } | null

  const loadLoginProps = useCallback(
    (): LoginProps =>
      !isLoginPath
        ? (getLoginPropsSessionRepository() ?? { loginType: 'normal' })
        : {
            loginType: isOAuth2Path ? 'oauth2' : isSsoPath ? 'sso' : 'normal',
            clientId: !isOAuth2OrSsoPath
              ? undefined
              : (searchParams.get('applicationClientId') ??
                searchParams.get('client_id') ??
                hashParamsStateParsed?.clientId),
            schoolAlias:
              getAliasFromUrl() ??
              searchParams.get('schoolAlias') ??
              searchParams.get('school_alias') ??
              hashParamsStateParsed?.schoolAlias,
            launchMethod: !isSsoPath
              ? undefined
              : (searchParams.get('launchMethod') ??
                searchParams.get('launch_method') ??
                hashParamsStateParsed?.launchMethod),
            redirectUri: !isOAuth2OrSsoPath
              ? undefined
              : (searchParams.get('redirectUri') ??
                searchParams.get('redirect_uri') ??
                hashParamsStateParsed?.redirectUri),
            customUrlRedirect: !isOAuth2OrSsoPath
              ? undefined
              : (searchParams.get('customQueryParams') ??
                searchParams.get('custom_url_redirect') ??
                hashParamsStateParsed?.customUrlRedirect),
            state: !isOAuth2OrSsoPath
              ? undefined
              : (searchParams.get('state') ?? hashParamsStateParsed?.state),
            responseType: !isOAuth2Path
              ? undefined
              : ((searchParams.get('responseType') ??
                  searchParams.get('response_type') ??
                  hashParamsStateParsed?.responseType) as OAuth2ResponseType),
            scope: !isOAuth2Path
              ? undefined
              : (searchParams.get('scope') ?? hashParamsStateParsed?.scope)
          },
    [hashParamsStateParsed, isOAuth2OrSsoPath, isOAuth2Path, isLoginPath, isSsoPath, searchParams]
  )

  const [state, setState] = useState<AuthStateProps>(() => {
    const apiClientData = loadHubApiClientService()
    const isSigned = !!apiClientData?.session && !isOAuth2OrSsoPath && !checkIsIdle()

    if (isSigned) {
      return {
        ...INITIAL_STATE,
        isSigned: true,
        isLoading: true,
        isLoginSupport: !!apiClientData.supportSession
      }
    }

    const loginProps = loadLoginProps()
    const whiteLabel = isLoginPath ? undefined : (getWhiteLabelSessionRepository() ?? undefined)
    const ssoApplication = isLoginPath
      ? undefined
      : (getLtiApplicationSessionRepository() ?? undefined)

    return {
      ...INITIAL_STATE,
      isLoadingWhiteLabel:
        isLoginPath &&
        ((!whiteLabel && !!loginProps.schoolAlias) ||
          (!!whiteLabel &&
            !!loginProps.schoolAlias &&
            whiteLabel.alias !== loginProps.schoolAlias)),
      isLoadingSsoApplication:
        isLoginPath &&
        ((!ssoApplication && !!loginProps.clientId) ||
          (!!ssoApplication &&
            !!loginProps.clientId &&
            [ssoApplication.clientId, ssoApplication.oAuth2ClientId].includes(
              loginProps.clientId
            ))),
      whiteLabel,
      ssoApplication,
      loginProps
    }
  })

  const setStateSafety = useCallback(
    (newData: Partial<AuthStateProps> | ((oldData: AuthStateProps) => Partial<AuthStateProps>)) => {
      if (typeof newData === 'function') {
        setState((oldData) => ({ ...oldData, ...newData(oldData) }))
      } else {
        setState((oldData) => ({ ...oldData, ...newData }))
      }
    },
    [setState]
  )

  const saveProfile = useCallback(
    (updatedProfile: ProfileDetailsFormatted) => {
      setStateSafety({ profile: updatedProfile })
    },
    [setStateSafety]
  )

  const saveWhiteLabel = useCallback(
    (updatedProfile: WhiteLabel) => {
      setStateSafety({ whiteLabel: updatedProfile })
    },
    [setStateSafety]
  )

  const saveAccount = useCallback(
    (updatedAccount: Account) => {
      setStateSafety({ account: updatedAccount })
    },
    [setStateSafety]
  )

  const getToken = useCallback(() => {
    return getTokenRepository()
  }, [])

  const prepareOAuth2AndSso = useCallback(() => {
    queryClient.clear()
    void queryClient.invalidateQueries()
    logoutSessionService()
    const stateToSet: Partial<AuthStateProps> = {}
    let loginProps: LoginProps | undefined = loadLoginProps()
    const hasAnyAuthAndSsoProp = Object.values(loginProps).filter((prop) => !!prop).length > 1
    if (hasAnyAuthAndSsoProp) {
      saveLoginPropsSessionRepository(loginProps)
    } else {
      if (locationState?.keepLoginProps) {
        loginProps = getLoginPropsSessionRepository() ?? undefined
      } else {
        removeLoginPropsSessionRepository()
      }
      stateToSet.loginProps = loginProps
    }
    if (!loginProps?.clientId) {
      stateToSet.ssoApplication = undefined
      removeLtiApplicationSessionRepository()
    }
    if (!loginProps?.schoolAlias) {
      stateToSet.whiteLabel = undefined
      removeWhiteLabelSessionRepository()
    }
    setStateSafety(stateToSet)
  }, [loadLoginProps, locationState?.keepLoginProps, queryClient, setStateSafety])

  const logout = useCallback(() => {
    navigate('/', { replace: true })
    queryClient.clear()
    void queryClient.invalidateQueries()
    logoutSessionService()
    setTheme({ mode: 'light', color: 'sysPrimary' })
    setStateSafety((oldState) => ({
      ...INITIAL_STATE,
      ssoApplication: oldState.ssoApplication,
      whiteLabel: oldState.loginProps?.schoolAlias ? oldState.whiteLabel : undefined,
      loginProps: oldState.loginProps
    }))
  }, [navigate, queryClient, setStateSafety, setTheme])

  const showSession = useCallback(
    async ({ shouldGoBack = true, onError, onSuccess }: ShowSessionHookParams): Promise<void> => {
      try {
        setStateSafety({ isLoading: true })

        const { profile, permissions, terms, whiteLabel, session, account } =
          await showSessionService()

        queryClient.clear()
        await queryClient.invalidateQueries()

        if (terms.length) {
          navigate('/terms', {
            state: {
              previousPath: shouldGoBack ? location.pathname : '/',
              term: terms[0]
            }
          })
        }

        setStateSafety({
          isLoading: false,
          isSigned: true,
          session,
          profile,
          account,
          permissions,
          whiteLabel: whiteLabel ?? undefined
        })
        onSuccess?.({
          profile,
          session,
          account,
          permissions,
          terms,
          whiteLabel,
          canNavigate: !!terms.length
        })
      } catch (error) {
        logout()
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    [handleError, location.pathname, logout, navigate, queryClient, setStateSafety]
  )

  const logoutSupport = useCallback(async () => {
    navigate('/', { replace: true })
    setStateSafety({ isLoginSupport: false })
    logoutSessionSupportService()
    await showSession({ shouldGoBack: false })
  }, [navigate, setStateSafety, showSession])

  const changeProfile = useCallback(
    async ({ model, onError, onSuccess }: ChangeProfileHookParams): Promise<void> => {
      setHubApiClientSession({
        userId: model.userId,
        schoolId: model.organization.code,
        orgId: model.organization.id,
        profileId: model.id,
        role: model.role.code
      })

      if (state.ssoApplication) {
        setStateSafety({ isLoading: true })
        return makeLtiLaunch.mutate({
          model: {
            applicationId: state.ssoApplication.id,
            launchMethod: state.loginProps?.launchMethod,
            customUrlRedirect: state.loginProps?.customUrlRedirect,
            redirectUri: state.loginProps?.redirectUri,
            state: state.loginProps?.state,
            responseType: state.loginProps?.responseType,
            scope: state.loginProps?.scope
          },
          onSuccess: (data) => {
            openApplicationLti(data, false)
            // DOCS: Do not turn isLoading to false, to still loading on load SSO application page
          },
          onError: () => {
            setStateSafety({ isLoading: false })
          }
        })
      }

      await showSession({
        shouldGoBack: false,
        onSuccess: (result) => {
          if (!result.canNavigate) {
            navigate('/', { replace: true })
          }
          onSuccess?.(result)
        },
        onError
      })
    },
    [
      makeLtiLaunch,
      navigate,
      setStateSafety,
      showSession,
      state.loginProps,
      state.ssoApplication,
      openApplicationLti
    ]
  )

  const createSession = useCallback(
    async <CurrentAuthFlow extends AuthFlow>({
      model,
      onError,
      onSuccess
    }: CreateSessionHookParams<CurrentAuthFlow>): Promise<void> => {
      let orgId = state.whiteLabel?.org.id
      if (model.authFlow === 'ESCOLA') {
        orgId = model.orgId
      }
      if (model.authFlow === 'SUPORTE') {
        orgId = model.institution
      }

      try {
        setStateSafety({
          isLoading: true,
          isLoadingGoogle: model.authFlow === 'SOCIAL' && model.type === 'GOOGLE',
          isLoadingMicrosoft: model.authFlow === 'SOCIAL' && model.type === 'MICROSOFT'
        })

        const { token } = await createSessionService(model)

        const { registers: profiles } = await listProfileAndSetCache.mutateAsync({
          model: { perPage: MAX_PER_PAGE, orgId, appId: state.ssoApplication?.id }
        })

        setStateSafety({
          isLoginSupport: model.authFlow === 'SUPORTE',
          isLoading: false,
          isLoadingGoogle: false,
          isLoadingMicrosoft: false
        })

        if (!profiles.length) {
          throw new NotFoundException({
            code: 'LOGIN.PROFILE_NOT_FOUND',
            message: i18n().modules.hub.auth.pages.login.errors.profileNotFound
          })
        }

        const sessionSupport = getSessionRepository()
        const tokenSupport = getTokenRepository()
        if (model.authFlow === 'SUPORTE' && sessionSupport && tokenSupport) {
          saveSessionSupportRepository(sessionSupport)
          saveTokenSupportRepository(tokenSupport)
          const profileFound = profiles.find((profile) => profile.id === model.profileId)
          if (profileFound) {
            await changeProfile({ model: profileFound })
          } else {
            await changeProfile({ model: profiles[0] })
          }
        } else if (profiles.length === 1) {
          await changeProfile({ model: profiles[0] })
        } else {
          navigate('/profiles', { state: { orgId } })
        }
        onSuccess?.({ token, profiles })
      } catch (error) {
        const parsedError = error as ApplicationException
        setStateSafety({ isLoading: false, isLoadingGoogle: false, isLoadingMicrosoft: false })
        if (
          parsedError.code === 'LOGIN.NEW_PASSWORD_REQUIRED' &&
          (model.authFlow === 'EMAIL' || model.authFlow === 'ESCOLA')
        ) {
          return navigate('/reset-password', {
            state: {
              username: model.username,
              password: model.password,
              authFlow: model.authFlow,
              orgId
            }
          })
        }
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    [
      changeProfile,
      listProfileAndSetCache,
      navigate,
      setStateSafety,
      state.ssoApplication,
      state.whiteLabel
    ]
  )

  const createSessionMicrosoft = useCallback(
    ({ onSuccess, onError }: CreateMicrosoftSessionHookParams): void => {
      try {
        setStateSafety({ isLoadingMicrosoft: true })
        const queryParams = new URLSearchParams({
          redirect_uri: `${window.location.origin}${state.loginProps?.loginType === 'oauth2' ? '/oauth2' : state.loginProps?.loginType === 'sso' ? '/sso' : ''}`,
          client_id: import.meta.env.VITE_MICROSOFT_CLIENT_ID,
          nonce: import.meta.env.VITE_MICROSOFT_CLIENT_ID,
          response_type: 'id_token',
          response_mode: 'fragment',
          prompt: 'select_account',
          scope: ['user.read', 'openid', 'profile', 'offline_access'].join(' '),
          state: JSON.stringify({
            loginType: 'microsoft',
            clientId: state.loginProps?.clientId,
            applicationId: state.ssoApplication?.id,
            schoolId: state.whiteLabel?.org.id,
            schoolAlias: state.loginProps?.schoolAlias,
            launchMethod: state.loginProps?.launchMethod,
            customUrlRedirect: state.loginProps?.customUrlRedirect,
            redirectUri: state.loginProps?.redirectUri,
            state: state.loginProps?.state,
            responseType: state.loginProps?.responseType,
            scope: state.loginProps?.scope
          } as AuthUrlParams)
        }).toString()

        window.location.assign(
          `${import.meta.env.VITE_MICROSOFT_OAUTH2_URL}/v2.0/authorize?${queryParams}`
        )
        debounceEvent(setStateSafety)({ isLoadingMicrosoft: false })
        onSuccess?.(null)
      } catch (error) {
        setStateSafety({ isLoadingMicrosoft: false })
        handleError({
          error: new UnprocessableEntityException({
            message: i18n().modules.hub.auth.pages.login.errors.loginMicrosoft
          })
        })
        onError?.({ error: error as ApplicationException })
      }
    },
    [handleError, setStateSafety, state.loginProps, state.ssoApplication, state.whiteLabel]
  )

  const createSessionGoogle = useCallback(
    ({ onSuccess, onError }: CreateGoogleSessionHookParams): void => {
      try {
        setStateSafety({ isLoadingGoogle: true })
        const queryParams = new URLSearchParams({
          redirect_uri: `${window.location.origin}${state.loginProps?.loginType === 'oauth2' ? '/oauth2' : state.loginProps?.loginType === 'sso' ? '/sso' : ''}`,
          client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
          response_type: 'token',
          prompt: 'select_account',
          scope: [
            'https://www.googleapis.com/auth/userinfo.profile',
            'https://www.googleapis.com/auth/userinfo.email'
          ].join(' '),
          state: JSON.stringify({
            loginType: 'google',
            clientId: state.loginProps?.clientId,
            applicationId: state.ssoApplication?.id,
            schoolId: state.whiteLabel?.org.id,
            schoolAlias: state.loginProps?.schoolAlias,
            launchMethod: state.loginProps?.launchMethod,
            customUrlRedirect: state.loginProps?.customUrlRedirect,
            redirectUri: state.loginProps?.redirectUri,
            state: state.loginProps?.state,
            responseType: state.loginProps?.responseType,
            scope: state.loginProps?.scope
          } as AuthUrlParams)
        }).toString()

        window.location.assign(`${import.meta.env.VITE_GOOGLE_OAUTH2_URL}/v2/auth?${queryParams}`)
        debounceEvent(setStateSafety)({ isLoadingGoogle: false })
        onSuccess?.(null)
      } catch (error) {
        setStateSafety({ isLoadingGoogle: false })
        handleError({
          error: new UnprocessableEntityException({
            message: i18n().modules.hub.auth.pages.login.errors.loginGoogle
          })
        })
        onError?.({ error: error as ApplicationException })
      }
    },
    [handleError, setStateSafety, state.loginProps, state.ssoApplication, state.whiteLabel]
  )

  useEffect(() => {
    if (state.isLoadingSsoApplication || state.isLoadingWhiteLabel) {
      return
    }

    if (hashParamsStateParsed?.loginType === 'microsoft' && !isCreatingSessionMicrosoft) {
      try {
        isCreatingSessionMicrosoft = true
        const tokenPayload = decodeTokenPayload<{ preferred_username: string }>(hashParams.id_token)
        void createSession({
          model: {
            authFlow: 'SOCIAL',
            username: tokenPayload.preferred_username,
            itk: hashParams.id_token,
            type: 'MICROSOFT'
          },
          onError: ({ error }) => {
            handleError({ error })
          }
        })
      } catch {
        handleError({
          error: new UnprocessableEntityException({
            message: i18n().modules.hub.auth.pages.login.errors.loginMicrosoft
          })
        })
      }
    }

    if (hashParamsStateParsed?.loginType === 'google' && !isCreatingSessionGoogle) {
      isCreatingSessionGoogle = true
      void createSession({
        model: {
          authFlow: 'SOCIAL',
          itk: hashParams.access_token,
          type: 'GOOGLE'
        },
        onError: ({ error }) => {
          handleError({ error })
        }
      })
    }
    // DOCS: This useEffect can only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isLoadingSsoApplication, state.isLoadingWhiteLabel])

  useEffect(() => {
    if (
      !state.isSigned &&
      isLoginPath &&
      state.loginProps?.schoolAlias &&
      state.loginProps.schoolAlias !== state.whiteLabel?.alias
    ) {
      showWhiteLabelByAliasService({ alias: state.loginProps.schoolAlias })
        .then((shownWhiteLabel) => {
          if (shownWhiteLabel.active) {
            saveWhiteLabelSessionRepository(shownWhiteLabel)
            setStateSafety({ isLoadingWhiteLabel: false, whiteLabel: shownWhiteLabel })
          } else {
            setStateSafety({ isLoadingWhiteLabel: false })
            addAlertMessage({
              alertKey: 'inactiveWhiteLabel',
              severity: 'warning',
              subTitle: i18n().modules.hub.auth.pages.login.errors.inactiveWhiteLabel
            })
            navigate('/not-found')
          }
        })
        .catch((error) => {
          let parsedError = error as ApplicationException
          if (parsedError.code === 'ERROR.THEME.NOT_FOUND') {
            parsedError = new NotFoundException()
          }
          setStateSafety({ isLoadingWhiteLabel: false })
          handleError({ error: parsedError })
          navigate('/not-found')
        })
    }
    // DOCS: This useEffect can only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      !state.isSigned &&
      isLoginPath &&
      state.loginProps?.clientId &&
      ![state.ssoApplication?.clientId, state.ssoApplication?.oAuth2ClientId].includes(
        state.loginProps.clientId
      )
    ) {
      showLtiApplicationService({
        clientId: state.loginProps.clientId,
        redirectUri: state.loginProps.redirectUri
      })
        .then((shownLtiApplication) => {
          saveLtiApplicationSessionRepository(shownLtiApplication)
          setStateSafety({ isLoadingSsoApplication: false, ssoApplication: shownLtiApplication })
        })
        .catch((error) => {
          const parsedError = error as ApplicationException
          const parsedErrorCode = parsedError.code?.replace('ERROR.', '') as 'INVALID_REDIRECT_URI'
          parsedError.message =
            i18n().modules.hub.lti.hooks.showApplication.exceptions[parsedErrorCode] ||
            parsedError.message
          setStateSafety({ isLoadingSsoApplication: false })
          handleError({ error: parsedError })
          navigate('/not-found')
        })
    }
    // DOCS: This useEffect can only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (state.isSigned && !state.profile) {
      void showSession({})
    }
    // DOCS: This useEffect can only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isOAuth2OrSsoPath && state.loginProps) {
      let subTitle = ''
      if (!state.loginProps.clientId) {
        subTitle = i18n().modules.hub.auth.pages.login.errors.clientId
      } else if (
        state.loginProps.launchMethod &&
        !['get', 'post'].includes(state.loginProps.launchMethod.toLocaleLowerCase())
      ) {
        subTitle = i18n().modules.hub.auth.pages.login.errors.launchMethod
      } else if (
        isOAuth2Path &&
        (!state.loginProps.responseType ||
          !['code', 'access_token', 'id_token'].includes(state.loginProps.responseType))
      ) {
        subTitle = i18n().modules.hub.auth.pages.login.errors.responseType
      } else if (isOAuth2Path && !state.loginProps.scope) {
        subTitle = i18n().modules.hub.auth.pages.login.errors.scope
      }

      if (subTitle) {
        navigate('/not-found')
        addAlertMessage({ alertKey: 'oAuth2OrSsoParamsMismatch', severity: 'warning', subTitle })
      }
    }
    // DOCS: This useEffect can only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const mode =
      state.account?.skin === theme.universe && state.profile?.role.code === 'aluno'
        ? 'universe'
        : 'light'
    if (
      !state.whiteLabel?.active ||
      !!(state.profile?.role.code && MONITOORA_ROLES.includes(state.profile.role.code)) ||
      state.profile?.role.code === 'billing'
    ) {
      return setTheme({ mode, color: 'sysPrimary' })
    }
    setTheme({ mode, color: state.whiteLabel.primaryColor })
  }, [setTheme, state.profile, state.whiteLabel, state.account])

  return (
    <AuthContext.Provider
      value={{
        ...state,
        saveProfile,
        saveWhiteLabel,
        saveAccount,
        getToken,
        prepareOAuth2AndSso,
        logout,
        showSession,
        logoutSupport,
        changeProfile,
        createSession,
        createSessionMicrosoft,
        createSessionGoogle
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth(): AuthContextProps {
  return useContext(AuthContext)
}
