import { AppCard } from '@/modules/hub/applications/components/AppCard'

import { HubLabAppCardProps } from './styles'

export function HubLabDesktopAppCard({
  isHighlight,
  css,
  ...restProps
}: HubLabAppCardProps): JSX.Element {
  return (
    <AppCard
      wrapperProps={{ css: { flex: 1 } }}
      css={{
        width: '100%',
        height: '100%',
        backgroundColor: '$alpha-white-40',
        borderRadius: '$lg',
        ...(isHighlight && {
          '& .Button-StateLayer': {
            gap: '$md',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            '& .DesktopAppCard-Content': {
              alignItems: 'flex-start',
              '& .DesktopAppCard-Application-Description': {
                textAlign: 'left'
              }
            }
          }
        }),
        ...css
      }}
      {...restProps}
    />
  )
}
