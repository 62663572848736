import { Button } from '@positivote/design-system/components/Button'
import { AddCircleIcon } from '@positivote/design-system/icons/AddCircle'
import { useNavigate } from 'react-router-dom'

export interface CreateRegisterButtonProps {
  path: string
  label: string
}

export function CreateRegisterButton({ path, label }: CreateRegisterButtonProps): JSX.Element {
  const navigate = useNavigate()
  return (
    <Button
      data-testid="CreateRegisterButton"
      onClick={() => navigate(path)}
      variant="tonal"
      LeadingIcon={<AddCircleIcon size={18} />}
      css={{ minWidth: 206 }}
    >
      {label}
    </Button>
  )
}
