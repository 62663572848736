import { Main } from '@positivote/design-system/components/Main'
import { StepProps, Stepper } from '@positivote/design-system/components/Stepper'
import { Breakpoint } from '@positivote/design-system/theme'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { ExitConfirmDialog } from '@/common/components/ExitConfirmDialog'
import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { DisciplineDataStepForm, DisciplineStepperState } from '@/modules/hub/disciplines/contracts'
import { useShowDiscipline } from '@/modules/hub/disciplines/hooks'

import { DisciplineDataStep } from './Data'
import { DisciplinePreviewStep } from './Preview'

export function FormDiscipline(): JSX.Element {
  const navigate = useNavigate()

  const params = useParams()
  const isEditing = !!params.id
  changePageTitle(i18n().modules.hub.disciplines.pages.form.pageTitle(isEditing))
  const [isLoading, setIsLoading] = useState(false)
  const [isDirty, setIsDirty] = useState(false)

  const [currentStepPosition, setCurrentStepPosition] = useState(1)
  const [nextLocation, setNextLocation] = useState(0)
  const [stepperState, setStepperState] = useState<DisciplineStepperState>({
    disciplineData: {
      form: null,
      disciplineBase: null,
      hasError: false,
      isValid: false,
      canGoNext: true
    },
    preview: {
      canGoNext: true
    }
  })

  const stepperRef = useRef<{
    validateDataForm: () => Promise<DisciplineDataStepForm | null>
    onSubmit: (setIsLoading: (isLoading: boolean) => void) => void
  }>(null)

  async function onClickNext(newStep: StepProps): Promise<void> {
    const disciplineDataStepFormData = await stepperRef.current?.validateDataForm()
    if (!disciplineDataStepFormData) {
      return
    }
    setStepperState((oldState) => ({
      ...oldState,
      disciplineData: {
        ...oldState.disciplineData,
        form: {
          ...disciplineDataStepFormData
        }
      },
      preview: {
        ...oldState.preview
      }
    }))
    setCurrentStepPosition(newStep.position)
  }

  const showDiscipline = useShowDiscipline({
    model: {
      disciplineId: params.id!
    },
    queryOptions: {
      enabled: !!params.id
    },
    onError: () => {
      navigate('/data-management/levels-and-classes/discipline')
    }
  })

  useEffect(() => {
    if (showDiscipline.data) {
      setStepperState({
        disciplineData: {
          form: {
            code: showDiscipline.data.code,
            disciplineBaseId: showDiscipline.data.courseBaseId,
            name: showDiscipline.data.name
          },
          disciplineBase: {
            id: showDiscipline.data.id,
            name: showDiscipline.data.courseBaseName
          },
          canGoNext: true,
          hasError: false,
          isValid: true
        },
        preview: {
          canGoNext: true
        }
      })
    }
  }, [showDiscipline.data])

  return (
    <Main
      css={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflowX: 'hidden'
      }}
    >
      <ExitConfirmDialog initialIsDirty={isDirty} location={nextLocation} />
      <AppBar
        title={i18n().modules.hub.dataManagement.pages.menu.appBar.title}
        goBackFunction={() => navigate(-1)}
        breadcrumbItems={[
          {
            label: i18n().modules.hub.disciplines.pages.form.appBar.breadcrumbs.overview,
            onClick: () => {
              setNextLocation(-3)
              navigate(-3)
            }
          },
          {
            label:
              i18n().modules.hub.disciplines.pages.form.appBar.breadcrumbs
                .educationLevelsAndClasses,
            onClick: () => {
              setNextLocation(-2)
              navigate(-2)
            }
          },
          {
            label: i18n().modules.hub.disciplines.pages.form.appBar.breadcrumbs.disciplines,
            onClick: () => {
              setNextLocation(-1)
              navigate(-1)
            }
          },
          {
            label:
              i18n().modules.hub.disciplines.pages.form.appBar.breadcrumbs[
                isEditing ? 'editDiscipline' : 'newDiscipline'
              ]
          }
        ]}
      />
      <Stepper
        orientation="horizontal"
        position="start"
        stepOrientation="horizontal"
        mobileProps={{ breakpoint: Breakpoint.sm, progressType: 'dots' }}
        isLoading={isLoading}
        nextButtonProps={{ onClick: onClickNext }}
        previousButtonProps={{
          onClick: (newStep) => setCurrentStepPosition(newStep.position)
        }}
        cancelButtonProps={{
          onClick: () => navigate(-1)
        }}
        doneButtonProps={{
          label: i18n().modules.hub.disciplines.pages.form.stepper.preview.saveDiscipline,
          onClick: () => {
            setIsDirty(false)
            stepperRef.current?.onSubmit(setIsLoading)
          }
        }}
        currentStepPosition={currentStepPosition}
        steps={[
          {
            position: 1,
            title: i18n().modules.hub.disciplines.pages.form.stepper.disciplineData.stepTitle,
            status: stepperState.disciplineData.hasError ? 'error' : 'active',
            canGoNext: stepperState.disciplineData.canGoNext,
            showPreviousButton: true,
            children: (
              <DisciplineDataStep
                onChangeIsDirty={(props) => setIsDirty(props)}
                stepState={stepperState.disciplineData}
                isLoading={showDiscipline.isFetching}
                ref={stepperRef}
                setStepState={(stepState) =>
                  setStepperState((oldState) => ({
                    ...oldState,
                    disciplineData: { ...oldState.disciplineData, ...stepState }
                  }))
                }
              />
            )
          },
          {
            position: 2,
            title: i18n().modules.hub.disciplines.pages.form.stepper.preview.stepTitle,
            status: 'active',
            canGoNext: true,
            showPreviousButton: true,
            children: <DisciplinePreviewStep ref={stepperRef} stepperState={stepperState} />
          }
        ]}
        css={{
          flex: 1,
          padding: '$lg',
          '& .StepperHeader-Container': { width: 500, padding: '$none' },
          '& .Stepper-ChildrenWrapper': {
            display: 'flex',
            flex: 1
          }
        }}
      />
    </Main>
  )
}
