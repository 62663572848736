import { Main } from '@positivote/design-system/components/Main'
import { StepProps, Stepper } from '@positivote/design-system/components/Stepper'
import { Breakpoint } from '@positivote/design-system/theme'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { ExitConfirmDialog } from '@/common/components/ExitConfirmDialog'
import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { ClassroomDataStepForm, ClassroomStepperState } from '@/modules/hub/classroom/contracts'
import { useShowClassroom } from '@/modules/hub/classroom/hooks'

import { ClassroomDataStep } from './Data'
import { ClassroomPreviewStep } from './Preview'

export function FormClassroom(): JSX.Element {
  const navigate = useNavigate()
  const params = useParams()
  const isEditing = !!params.id
  changePageTitle(i18n().modules.hub.classroom.pages.form.pageTitle(isEditing))
  const [isLoading, setIsLoading] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const location = useLocation()
  const locationState = location.state as {
    schoolYear: { name: string; id: number }
  } | null
  const [currentStepPosition, setCurrentStepPosition] = useState(1)
  const [stepperState, setStepperState] = useState<ClassroomStepperState>({
    classroomData: {
      form: null,
      associatedSchoolYearName: null,
      schoolYear: null,
      hasError: false,
      isValid: false,
      canGoNext: true
    },
    preview: {
      canGoNext: true
    }
  })

  const stepperRef = useRef<{
    validateDataForm: () => Promise<ClassroomDataStepForm | null>
    onSubmit: (setIsLoading: (isLoading: boolean) => void) => void
  }>(null)

  async function onClickNext(newStep: StepProps): Promise<void> {
    const classroomDataStepFormData = await stepperRef.current?.validateDataForm()
    if (!classroomDataStepFormData) {
      return
    }
    setStepperState((oldState) => ({
      ...oldState,
      classroomData: {
        ...oldState.classroomData,
        form: {
          ...classroomDataStepFormData
        }
      },
      preview: {
        ...oldState.preview
      }
    }))
    setCurrentStepPosition(newStep.position)
  }

  const showClassroom = useShowClassroom({
    model: {
      classroomId: Number(params.id!)
    },
    queryOptions: {
      enabled: !!params.id
    },
    onError: () => {
      navigate('/data-management/levels-and-classes/classroom')
    }
  })

  useEffect(() => {
    if (showClassroom.data && locationState?.schoolYear) {
      setStepperState({
        classroomData: {
          form: {
            code: showClassroom.data.code,
            associatedSchoolYearId: showClassroom.data.level.id,
            name: showClassroom.data.name
          },
          associatedSchoolYearName:
            i18n().modules.hub.classroom.fullClassLevel[showClassroom.data.level.code],
          schoolYear: {
            id: locationState.schoolYear.id,
            name: locationState.schoolYear.name
          },
          canGoNext: true,
          hasError: false,
          isValid: true
        },
        preview: {
          canGoNext: true
        }
      })
    }
  }, [locationState, navigate, showClassroom.data])

  useEffect(() => {
    if (!locationState?.schoolYear) {
      navigate('/data-management/levels-and-classes/classroom')
    }
    // DOCS: only render when first loader
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Main
      css={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflowX: 'hidden'
      }}
    >
      <ExitConfirmDialog initialIsDirty={isDirty} />
      <AppBar
        title={i18n().modules.hub.classroom.pages.form.appBar.title}
        goBackFunction={() => navigate(-1)}
        breadcrumbItems={[
          {
            label: i18n().modules.hub.classroom.pages.form.appBar.breadcrumbs.overview,
            onClick: () => {
              navigate(-3)
            }
          },
          {
            label:
              i18n().modules.hub.classroom.pages.form.appBar.breadcrumbs.educationLevelsAndClasses,
            onClick: () => {
              navigate(-2)
            }
          },
          {
            label: i18n().modules.hub.classroom.pages.form.appBar.breadcrumbs.classroom,
            onClick: () => {
              navigate(-1)
            }
          },
          {
            label:
              i18n().modules.hub.classroom.pages.form.appBar.breadcrumbs[
                isEditing ? 'editClassroom' : 'newClassroom'
              ]
          }
        ]}
      />
      <Stepper
        orientation="horizontal"
        position="start"
        stepOrientation="horizontal"
        mobileProps={{ breakpoint: Breakpoint.sm, progressType: 'dots' }}
        isLoading={isLoading}
        nextButtonProps={{ onClick: onClickNext }}
        previousButtonProps={{
          onClick: (newStep) => setCurrentStepPosition(newStep.position)
        }}
        cancelButtonProps={{
          onClick: () => navigate(-1)
        }}
        doneButtonProps={{
          label: i18n().modules.hub.classroom.pages.form.stepper.preview.saveClassroom,
          onClick: () => {
            setIsDirty(false)
            stepperRef.current?.onSubmit(setIsLoading)
          }
        }}
        currentStepPosition={currentStepPosition}
        steps={[
          {
            position: 1,
            title: i18n().modules.hub.classroom.pages.form.stepper.classroomData.stepTitle,
            status: stepperState.classroomData.hasError ? 'error' : 'active',
            canGoNext: stepperState.classroomData.canGoNext,
            showPreviousButton: true,
            children: (
              <ClassroomDataStep
                isLoading={showClassroom.isFetching}
                onChangeIsDirty={(props) => setIsDirty(props)}
                stepState={stepperState.classroomData}
                ref={stepperRef}
                setStepState={(stepState) =>
                  setStepperState((oldState) => ({
                    ...oldState,
                    classroomData: { ...oldState.classroomData, ...stepState }
                  }))
                }
              />
            )
          },
          {
            position: 2,
            title: i18n().modules.hub.classroom.pages.form.stepper.preview.stepTitle,
            status: 'active',
            canGoNext: true,
            showPreviousButton: true,
            children: <ClassroomPreviewStep ref={stepperRef} stepperState={stepperState} />
          }
        ]}
        css={{
          flex: 1,
          padding: '$lg',
          '& .StepperHeader-Container': { width: 500, padding: '$none' },
          '& .Stepper-ChildrenWrapper': {
            display: 'flex',
            flex: 1
          }
        }}
      />
    </Main>
  )
}
