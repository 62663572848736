import { useNavigate } from 'react-router-dom'

import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { ListClassroom } from '@/modules/hub/classroom/components/List'

export function QuickAccessSchoolClassList(): JSX.Element {
  changePageTitle(i18n().modules.hub.quickAccess.pages.schoolClassList.pageTitle)
  const navigate = useNavigate()

  return (
    <ListClassroom
      appBarTitle={i18n().modules.hub.quickAccess.pages.schoolClassList.appBar.title}
      navigateTo={(params) => navigate(`/quick-access/classroom/${params}/generete`)}
    />
  )
}
