import { ArrowBackIcon } from '@positivote/design-system/icons/ArrowBack'
import { CloseIcon } from '@positivote/design-system/icons/Close'
import { useEffect, useState } from 'react'
import { useBlocker, useNavigate } from 'react-router-dom'

import { TextDialog } from '@/common/components/TextDialog'
import { i18n } from '@/common/i18n'

export interface ExitConfirmDialogProps {
  initialIsDirty: boolean
  location?: number
}

export function ExitConfirmDialog({
  initialIsDirty,
  location
}: ExitConfirmDialogProps): JSX.Element {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [navigationProps, setNavigationProps] = useState({
    nextLocation: '',
    historyAction: ''
  })
  const [isDirty, setIsDirty] = useState(false)
  const navigate = useNavigate()

  useBlocker(({ nextLocation, historyAction }): boolean => {
    setNavigationProps({
      nextLocation: nextLocation.pathname,
      historyAction
    })
    setIsDialogOpen(true)
    return isDirty
  })

  useEffect(() => {
    setIsDirty(initialIsDirty)
  }, [initialIsDirty])

  return (
    <TextDialog
      data-testid="exitConfirmDialog"
      isOpen={isDialogOpen}
      title={{ label: i18n().common.components.exitConfirmDialog.title }}
      contentTexts={[i18n().common.components.exitConfirmDialog.contentTexts]}
      onCancel={() => setIsDialogOpen(false)}
      refuseAction={{
        icon: <ArrowBackIcon size={18} />,
        label: i18n().common.components.exitConfirmDialog.refuseActionTitle,
        handle: () => setIsDialogOpen(false)
      }}
      acceptAction={{
        icon: <CloseIcon size={18} />,
        label: i18n().common.components.exitConfirmDialog.acceptActionTitle,
        handle: () => {
          setIsDirty(false)
          setTimeout(
            () =>
              location
                ? navigate(location)
                : navigationProps.historyAction === 'POP'
                  ? navigate(-1)
                  : navigate(navigationProps.nextLocation),
            100
          )
        }
      }}
      css={{ maxWidth: 600 }}
    />
  )
}
