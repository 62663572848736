import { AutologinMessageEvent } from '@/modules/hub/applications/contracts/models'

export const applicationPt = {
  device: {
    SMARTPHONE: 'Smartphone',
    TABLET: 'Tablet',
    DESKTOP: 'Desktop'
  },
  resources: {
    list: 'Listar aplicações'
  },
  components: {
    appBaseCard: {
      toAccess: 'Acessar',
      configureAccess: 'Configurar acesso',
      textDialog: {
        contentText: 'Seu aplicativo está pronto!',
        acceptAction: 'Abrir'
      }
    }
  },
  hooks: {
    handleAccessApplication: {
      exceptions: {
        SHOW_HUBOT_USER_FLOW_EVENTS: (_error: AutologinMessageEvent['error']): string =>
          'Ocorreu um erro ao listar credenciais no hubot',
        EXECUTE_AUTOLOGIN_EVENT: (error: AutologinMessageEvent['error']): string =>
          `Ocorreu um erro no acesso, provavelmente ${
            error.details.position === 'last'
              ? 'por credênciais incorretas.'
              : 'a aplicação está fora do ar ou teve alguma alteração na tela de login.'
          }`,
        UNKNOWN: (_error: AutologinMessageEvent['error']): string =>
          'Ocorreu um erro inesperado no acesso'
      },
      autologinModal: {
        title: 'Disponível no App Hub Educacional',
        contentText:
          'Sabia que com o App Hub Educacional você pode acessar este aplicativo sem precisar digitar seu login e senha todas as vezes?',
        refuseButton: 'Continuar no navegador',
        acceptButton: 'Baixar o App Hub Educacional'
      },
      incompatibleModal: {
        title: 'Acesso restrito!',
        contentText: (roles: string[]): string =>
          `Esta aplicação destina-se exclusivamente a perfis de ${roles
            .join(', ')
            .toLowerCase()
            .replace(
              /(?:,)([^,]+)$/,
              ' ou$1'
            )}. Para ter acesso, ajuste seu cadastro e inclua o perfil de ${roles
            .join(', ')
            .toLowerCase()
            .replace(/(?:,)([^,]+)$/, ' ou$1')}`,
        acceptButton: 'Ok',
        refuseButton: 'Trocar perfil'
      },
      deviceIncompatibleModal: {
        title: 'Dispositivo não compatível',
        contentText: (devices: string[]): string =>
          `Esta aplicação é compatível apenas com ${devices
            .join(', ')
            .toLowerCase()
            .replace(
              /(?:,)([^,]+)$/,
              ' e$1'
            )}. Para maiores informações, entre em contato com o nosso time de suporte. Para obter mais informações, entre em contato com a sua instituição.`,
        acceptButton: 'Ok'
      }
    }
  },
  pages: {
    list: {
      pageTitle: 'Inicial',
      appBar: {
        title: 'Inicial',
        searchLabel: 'Nome do Aplicativo',
        selectSearch: {
          label: 'Ano escolar',
          defaultLabel: 'Todos os anos'
        },
        breadcrumbs: {
          overview: 'Visão geral',
          search: 'Pesquisa'
        }
      },
      emptyResult: {
        title: 'Ops! Parece que não há nenhum app na sua biblioteca',
        subTitle: 'Aguarde o processamento do sistema para começar gerenciar seus acessos'
      },
      results: 'Resultados da busca...',
      searching: 'Buscando por resultados...',
      emptyList: 'Não há resultados para esta busca!',
      cardMenu: {
        configureAccess: 'Configurar Acesso',
        knowMore: 'Saiba Mais'
      },
      applications: 'Aplicações Integradas',
      complementaryApplications: 'Aplicações Complementares',
      hubLab: 'Hub Lab'
    }
  }
}
