import { Navigate, Route, Routes } from 'react-router-dom'

import { FormDiscipline } from './pages/Form'
import { ListDiscipline } from './pages/List'

export function DisciplineRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<ListDiscipline />} />
      <Route path="/form" element={<FormDiscipline />} />
      <Route path="/form/:id" element={<FormDiscipline />} />

      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </Routes>
  )
}
