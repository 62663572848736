import { UseMutationResult, useMutation } from '@tanstack/react-query'

import { ApplicationException } from '@/common/exceptions'
import { useErrorHandler } from '@/common/hooks'

import { MakeLtiLaunchHookParams, MakeLtiLaunchHookResult } from './contracts/hooks'
import { makeLtiLaunchService } from './services'

export const ltiHookKey = 'lti'

export function useMakeLtiLaunch(): UseMutationResult<
  MakeLtiLaunchHookResult,
  ApplicationException,
  MakeLtiLaunchHookParams
> {
  const { handleError } = useErrorHandler()

  return useMutation({
    mutationKey: [ltiHookKey, 'makeLaunch'],
    mutationFn: async ({ model, onSuccess, onError }: MakeLtiLaunchHookParams) => {
      try {
        const ltiLaunchResult = await makeLtiLaunchService(model)
        onSuccess?.(ltiLaunchResult)

        return ltiLaunchResult
      } catch (error) {
        const parsedError = error as ApplicationException
        onError?.({ error: parsedError })
        handleError({ error: parsedError })
        throw parsedError
      }
    }
  })
}
