import { parseValidDateOrDefault } from '@/common/helpers'

import { ListUserApiResult, ShortUser } from './contracts'

export function fromUserApiSanitizer(profiles: ListUserApiResult['data']): ShortUser[] {
  return profiles.map((profile) => ({
    ...profile,
    allowedImageUseOnEdtech: profile.allowed_image_use_on_edtech,
    birthday: parseValidDateOrDefault(profile.birthday),
    organization: {
      id: profile.institution_id,
      code: profile.institution_code,
      name: profile.institution_name,
      address: profile.institution_address
    },
    role: {
      id: profile.role_id,
      code: profile.role_code,
      name: profile.role_name
    },
    userId: profile.user_id
  }))
}
