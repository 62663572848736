import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Checkbox } from '@positivote/design-system/components/Checkbox'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { IconButton } from '@positivote/design-system/components/IconButton'
import { Loader } from '@positivote/design-system/components/Loader'
import { Main } from '@positivote/design-system/components/Main'
import { Pagination } from '@positivote/design-system/components/Pagination'
import { Span } from '@positivote/design-system/components/Span'
import { TextField } from '@positivote/design-system/components/TextField'
import { Typography } from '@positivote/design-system/components/Typography'
import { UL } from '@positivote/design-system/components/UL'
import { useTheme } from '@positivote/design-system/hooks'
import { CancelIcon } from '@positivote/design-system/icons/Cancel'
import { DeleteIcon } from '@positivote/design-system/icons/Delete'
import { EditIcon } from '@positivote/design-system/icons/Edit'
import { FilterListIcon } from '@positivote/design-system/icons/FilterList'
import { SearchIcon } from '@positivote/design-system/icons/Search'
import { Breakpoint } from '@positivote/design-system/theme'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { CreateRegisterButton } from '@/common/components/CreateRegisterButton'
import { EmptyList } from '@/common/components/EmptyList'
import { EmptySearch } from '@/common/components/EmptySearch'
import { TextDialog } from '@/common/components/TextDialog'
import {
  DEFAULT_BREAK_POINT_PER_PAGE,
  XL_BREAK_POINT_PER_PAGE
} from '@/common/constants/react-query'
import { changePageTitle, debounceEvent } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { ListDisciplineHookParams } from '@/modules/hub/disciplines/contracts'
import {
  useBulkRemoveDiscipline,
  useListDiscipline,
  useRemoveDiscipline
} from '@/modules/hub/disciplines/hooks'

export function ListDiscipline(): JSX.Element {
  changePageTitle(i18n().modules.hub.disciplines.pages.list.pageTitle)
  const { profile } = useAuth()
  const navigate = useNavigate()
  const { breakpoint } = useTheme()
  const [showFilter, setShowFilter] = useState(false)
  const [disciplineIdsToRemove, setDisciplineIdsToRemove] = useState<number[]>([])
  const [removeDisciplineDialog, setRemoveDisciplineDialog] = useState<{
    title: string
    id: number
    isBulk: boolean
  } | null>(null)

  const [disciplineParams, setDisciplineParams] = useState<ListDisciplineHookParams['model']>({
    page: 1,
    perPage: breakpoint === Breakpoint.xl ? XL_BREAK_POINT_PER_PAGE : DEFAULT_BREAK_POINT_PER_PAGE,
    institutionId: profile!.organizationId
  })
  const listDisciplines = useListDiscipline({
    model: disciplineParams,
    queryOptions: {
      enabled: !!profile?.organizationId
    }
  })
  const bulkRemoveDisciplines = useBulkRemoveDiscipline()
  const removeDiscipline = useRemoveDiscipline()

  const isAllDisciplinesSelected = useMemo(() => {
    const disciplineIds = listDisciplines.data?.registers.map((discipline) => discipline.id) ?? []
    return disciplineIds.every((id) => disciplineIdsToRemove.includes(id))
  }, [listDisciplines.data?.registers, disciplineIdsToRemove])

  function handleBulkRemoveDisciplines(): void {
    if (disciplineParams.page && disciplineParams.perPage) {
      bulkRemoveDisciplines.mutate({
        model: {
          ids: disciplineIdsToRemove
        },
        institutionId: profile!.organizationId,
        page: disciplineParams.page,
        perPage: disciplineParams.perPage,
        onSuccess: () => {
          if (listDisciplines.data?.registers.length === 1 || isAllDisciplinesSelected) {
            setShowFilter(false)
            setDisciplineParams((oldData) => ({
              ...oldData,
              page: (oldData.page ?? 1) - 1 || 1
            }))
          }
          setRemoveDisciplineDialog(null)
        }
      })
    }
  }

  function handleRemoveDiscipline(id: number): void {
    if (disciplineParams.page && disciplineParams.perPage) {
      removeDiscipline.mutate({
        model: {
          id
        },
        institutionId: profile!.organizationId,
        page: disciplineParams.page,
        perPage: disciplineParams.perPage,
        onSuccess: () => {
          if (listDisciplines.data?.registers.length === 1) {
            setShowFilter(false)
            setDisciplineParams((oldData) => ({
              ...oldData,
              page: (oldData.page ?? 1) - 1 || 1
            }))
          }
          setDisciplineIdsToRemove((prevSelected) =>
            prevSelected.filter((disciplineId) => disciplineId !== id)
          )
          setRemoveDisciplineDialog(null)
        }
      })
    }
  }

  function handleChangeSearchText(event: React.ChangeEvent<HTMLInputElement>): void {
    const searchBy = event.target.value || undefined
    debounceEvent(() => {
      setDisciplineParams((oldFilters) => ({ ...oldFilters, search: searchBy!, page: 1 }))
    })()
  }

  function handleSelectAllDisciplines(): void {
    const disciplineIds = listDisciplines.data?.registers.map((discipline) => discipline.id) ?? []

    const allSelected = disciplineIds.every((id) => disciplineIdsToRemove.includes(id))

    if (allSelected) {
      setDisciplineIdsToRemove((prevSelected) =>
        prevSelected.filter((id) => !disciplineIds.includes(id))
      )
    } else {
      setDisciplineIdsToRemove((prevSelected) => [...prevSelected, ...disciplineIds])
    }
  }

  function handleSelectDisciplines(id: number): void {
    setDisciplineIdsToRemove((old) => {
      if (old.includes(id)) {
        return old.filter((disciplineId) => disciplineId !== id)
      } else {
        return [...old, id]
      }
    })
  }

  useEffect(() => {
    if (listDisciplines.data?.registers.length && !showFilter) {
      setShowFilter(true)
    }
  }, [listDisciplines.data?.registers.length, showFilter])

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <TextDialog
        data-testid="removeDiscipline"
        isOpen={!!removeDisciplineDialog?.id || !!removeDisciplineDialog?.isBulk}
        title={{
          label: i18n().modules.hub.disciplines.pages.list.removeDialog.title(
            removeDisciplineDialog?.isBulk ?? false
          )
        }}
        contentTexts={
          removeDisciplineDialog?.isBulk
            ? [
                i18n().modules.hub.disciplines.pages.list.removeDialog.bulkContentTextMain,
                i18n().modules.hub.disciplines.pages.list.removeDialog.bulkContentText
              ]
            : [
                <>
                  <Typography
                    data-testid="Typography-removeDialog-contentTextMain"
                    variant="bodyLarge"
                    css={{ color: '$on-surface-variant' }}
                  >
                    {i18n().modules.hub.disciplines.pages.list.removeDialog.contentTextMain}
                  </Typography>
                  <Div>
                    <Typography
                      data-testid="Typography-removeDialog-contentText"
                      variant="bodyLarge"
                      css={{ color: '$on-surface-variant' }}
                    >
                      {i18n().modules.hub.disciplines.pages.list.removeDialog.contentText}
                      <Span css={{ fontWeight: 'bold' }}>
                        {i18n().modules.hub.disciplines.pages.list.removeDialog.selectedName(
                          removeDisciplineDialog?.title
                        )}
                      </Span>
                    </Typography>
                  </Div>
                </>
              ]
        }
        onCancel={() => setRemoveDisciplineDialog(null)}
        refuseAction={{
          icon: <CancelIcon size={18} />,
          label: i18n().modules.billing.serviceMapping.pages.list.removeDialog.refuse,
          handle: () => setRemoveDisciplineDialog(null)
        }}
        acceptAction={{
          icon: <DeleteIcon size={18} />,
          label: i18n().modules.hub.disciplines.pages.list.removeDialog.accept,
          handle: () =>
            removeDisciplineDialog?.isBulk
              ? handleBulkRemoveDisciplines()
              : handleRemoveDiscipline(removeDisciplineDialog!.id)
        }}
        isLoading={
          removeDisciplineDialog?.isBulk
            ? bulkRemoveDisciplines.isPending
            : removeDiscipline.isPending
        }
        css={{
          maxWidth: 600,
          '& .Dialog-Content': {
            gap: '$none'
          }
        }}
      />
      <AppBar
        title={i18n().modules.hub.schoolYear.pages.list.appBar.title}
        goBackFunction={() => navigate(-1)}
        trailingElement={
          !!listDisciplines.data?.registers.length && (
            <CreateRegisterButton
              label={i18n().modules.hub.disciplines.pages.list.includeNewDiscipline}
              path="/data-management/levels-and-classes/discipline/form"
            />
          )
        }
        breadcrumbItems={[
          {
            label: i18n().modules.hub.disciplines.pages.list.appBar.breadcrumbs.overview,
            onClick: () => {
              navigate(-2)
            }
          },
          {
            label:
              i18n().modules.hub.disciplines.pages.list.appBar.breadcrumbs
                .educationLevelsAndClasses,
            onClick: () => {
              navigate(-1)
            }
          },
          {
            label: i18n().modules.hub.disciplines.pages.list.appBar.breadcrumbs.disciplines
          }
        ]}
      />
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '$lg',
          overflowY: 'auto',
          '@sm': { padding: '$md' }
        }}
      >
        {showFilter && (
          <>
            <Div css={{ display: 'flex', alignItems: 'center', gap: '$2xs', marginBottom: '$md' }}>
              <FilterListIcon size={18} />
              <Typography
                data-testid="Typography-titleFilter"
                variant="titleMedium"
                css={{ color: '$on-surface' }}
              >
                {i18n().modules.hub.disciplines.pages.list.filter.title}
              </Typography>
            </Div>
            <Div
              css={{
                display: 'flex',
                gap: '$md'
              }}
            >
              <TextField
                data-testid="search"
                leadingIcon={{ icon: SearchIcon }}
                label={i18n().modules.hub.disciplines.pages.list.filter.search}
                variant="outlined"
                css={{ width: 550 }}
                inputProps={{
                  onChange: handleChangeSearchText
                }}
              />
            </Div>
          </>
        )}
        {!listDisciplines.data?.registers.length ? (
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              padding: '$lg',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {listDisciplines.isFetching && (
              <Loader data-testid="Loader-Container-DisciplineList" size={80} />
            )}
            {!listDisciplines.isFetching && !disciplineParams.search && (
              <Div
                css={{ display: 'flex', flexDirection: 'column', gap: '$md', alignItems: 'center' }}
              >
                <EmptyList title={i18n().modules.hub.disciplines.pages.list.emptyList} />
                <CreateRegisterButton
                  label={i18n().modules.hub.disciplines.pages.list.includeNewDiscipline}
                  path="/data-management/levels-and-classes/discipline/form"
                />
              </Div>
            )}
            {!listDisciplines.isFetching && disciplineParams.search && <EmptySearch />}
          </Div>
        ) : (
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: '$sm',
              flex: 1
            }}
          >
            <Grid spacing="$md" css={{ padding: '$md' }}>
              <Grid xl={3} css={{ display: 'flex', alignItems: 'center', gap: '$md' }}>
                <Checkbox
                  data-testid="allDisciplines"
                  inputProps={{ checked: isAllDisciplinesSelected }}
                  onClick={handleSelectAllDisciplines}
                />
                <Typography
                  data-testid="Typography-header-discipline"
                  variant="titleMedium"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                >
                  {i18n().modules.hub.disciplines.pages.list.discipline}
                </Typography>
              </Grid>

              <Grid xl={3}>
                <Typography
                  data-testid="Typography-header-code"
                  variant="titleMedium"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                >
                  {i18n().modules.hub.disciplines.pages.list.code}
                </Typography>
              </Grid>
              <Grid xl={3}>
                <Typography
                  data-testid="Typography-header-base-discipline"
                  variant="titleMedium"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                >
                  {i18n().modules.hub.disciplines.pages.list.disciplineBase}
                </Typography>
              </Grid>
              <Grid xl={3} css={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton
                  data-testid="remove-all"
                  variant="standard"
                  disabled={!disciplineIdsToRemove.length}
                  onClick={() =>
                    setRemoveDisciplineDialog((oldData) => ({ ...oldData!, isBulk: true }))
                  }
                >
                  <DeleteIcon size={24} />
                </IconButton>
              </Grid>
            </Grid>
            <UL
              css={{
                display: 'flex',
                flexDirection: 'column',
                gap: '$sm',
                ...(listDisciplines.isFetching && {
                  ...(listDisciplines.data.lastPage <= 1 && { flex: 1 }),
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative'
                })
              }}
            >
              {listDisciplines.isFetching && (
                <Loader data-testid="Loader-Container-DisciplineList" size={80} />
              )}

              {listDisciplines.data.registers.map((discipline, index) => (
                <BaseCard
                  key={discipline.id}
                  data-testid={`BaseCard-Container-disciplineListItem-${index}`}
                  css={{
                    opacity: listDisciplines.isFetching ? '$transparent' : '$default',
                    borderRadius: '$lg',
                    backgroundColor: '$surface-1',
                    '& .BaseCard-StateLayer': {
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '$lg',
                      padding: '$md'
                    }
                  }}
                >
                  <Grid spacing="$md" css={{ flex: 1, alignItems: 'center' }}>
                    <Grid xl={3} css={{ display: 'flex', alignItems: 'center', gap: '$md' }}>
                      <Checkbox
                        data-testid={`select-discipline-${index}`}
                        inputProps={{
                          checked: disciplineIdsToRemove.includes(discipline.id),
                          onClick: () => handleSelectDisciplines(discipline.id)
                        }}
                      />
                      <Typography
                        data-testid={`Typography-name-${index}`}
                        lineClamp={2}
                        variant="bodyMedium"
                        css={{ color: '$on-surface' }}
                      >
                        {discipline.name}
                      </Typography>
                    </Grid>

                    <Grid xl={3}>
                      <Typography
                        data-testid={`Typography-code-${index}`}
                        lineClamp={2}
                        variant="bodyMedium"
                        css={{ color: '$on-surface' }}
                      >
                        {discipline.code}
                      </Typography>
                    </Grid>
                    <Grid xl={3}>
                      <Typography
                        data-testid={`Typography-courseBaseName-${index}`}
                        lineClamp={2}
                        variant="bodyMedium"
                        css={{ color: '$on-surface' }}
                      >
                        {discipline.courseBaseName}
                      </Typography>
                    </Grid>
                    <Grid xl={3} css={{ display: 'flex', justifyContent: 'flex-end', gap: '$md' }}>
                      <IconButton
                        data-testid={`edit-${index}`}
                        variant="standard"
                        onClick={() =>
                          navigate(
                            `/data-management/levels-and-classes/discipline/form/${discipline.id}`,
                            { state: { page: disciplineParams.page } }
                          )
                        }
                      >
                        <EditIcon size={24} />
                      </IconButton>
                      <IconButton
                        data-testid={`remove-${index}`}
                        variant="standard"
                        onClick={() =>
                          setRemoveDisciplineDialog({
                            id: discipline.id,
                            title: discipline.name,
                            isBulk: false
                          })
                        }
                      >
                        <DeleteIcon size={24} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </BaseCard>
              ))}
            </UL>
            {listDisciplines.data.lastPage > 1 && (
              <Pagination
                lastPage={listDisciplines.data.lastPage}
                page={disciplineParams.page ?? 1}
                setPage={(page) => setDisciplineParams((oldState) => ({ ...oldState, page }))}
              />
            )}
          </Div>
        )}
      </Div>
    </Main>
  )
}
