import { Navigate, Route, Routes } from 'react-router-dom'

import { FormQuickAccess } from './pages/Form'
import { QuickAccessSchoolClassList } from './pages/SchoolClassList'

export function QuickAccessRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/school-classes" element={<QuickAccessSchoolClassList />} />
      <Route path="/classroom/:id/generete" element={<FormQuickAccess />} />

      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </Routes>
  )
}
