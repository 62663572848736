import { Navigate, Route, Routes } from 'react-router-dom'

import { Ciasc } from './pages/Ciasc'

export function MonitooraRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<Ciasc />} />

      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </Routes>
  )
}
