import { i18n } from '@/common/i18n'

import {
  ClassLevelFormatted,
  Classroom,
  ClassroomFormatted,
  ListClassLevelApiResult,
  ListClassroomApiResult
} from './contracts'

export function classroomFormatSanitizer(
  schoolClasses: ListClassroomApiResult['data']
): ClassroomFormatted[] {
  return schoolClasses.map((schoolClass) => ({
    ...schoolClass,
    levelFormatted: {
      ...schoolClass.level,
      code: i18n().modules.hub.classroom.abbreviatedSegmentClassLevel[schoolClass.level.code]
    }
  }))
}

export function classroomSanitizer(classroom: Classroom): ClassroomFormatted {
  return {
    ...classroom,
    levelFormatted: {
      ...classroom.level,
      code: i18n().modules.hub.classroom.abbreviatedSegmentClassLevel[classroom.level.code]
    }
  }
}

export function listClassLevelSanitizer(
  classLevel: ListClassLevelApiResult['data']
): ClassLevelFormatted[] {
  return classLevel.map((level) => ({
    ...level,
    fullNameClassLevelFormatted: i18n().modules.hub.classroom.fullClassLevel[level.code]
  }))
}
